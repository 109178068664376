(function(ng) {
    "use strict";
    var module = ng.module('app.customers.services', [
        'app.navigation', 'app.services', 'app.lib'
    ]);

    module.factory('Customer', [
        'lodash', 'inherit', 'Entity', 'Memento',
        function(lodash, inherit, Entity, Memento) {
            function Customer(json) {
                Entity.call(this, json);
            }
            inherit(Customer, [Entity, Memento]);
            return Customer;
        }
    ]);

    module.factory('customerService', [
        '$http', '$q', 'Customer', 'Pager',
        function($http, $q, Customer, Pager) {
            var instance = {
                page: new Pager()
            };

            instance.index = function(page, subset, term) {
                var params = {
                    page: page || 1,
                    subset: subset || 'all'
                };
                if (term) {
                    params.q = term.replace(/^\s+|\s+$/g, '')
                }

                return $http.get('/api/customers', { params: params })
                    .success(function(data, status, headers, config) {
                        instance.page.update(data.page);
                    });
            };

            instance.post = function(customer) {
                var deferred = $q.defer();
                var patch = customer.save();
                delete patch.diff.id;
                $http.post('/api/customers', patch.diff)
                    .success(function(data) {
                        customer.replace(data.customer);
                        deferred.resolve(customer);
                    })
                    .error(function(data, status, headers, config) {
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.get = function(id) {
                var deferred = $q.defer();

                $http.get('/api/customers/' + id.toString())
                    .success(function(response) {
                        deferred.resolve(new Customer(response.customer));
                    })
                    .error(function(data, status) {
                        deferred.reject('The server is not responding. (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.patch = function(customer) {
                var deferred = $q.defer();
                var patch = customer.save();
                if (patch.diff) {
                    $http({
                        method: 'PATCH',
                        url: '/api/customers/' + customer.id.toString(),
                        data: patch.diff
                    }).success(function() {
                        deferred.resolve(customer);
                    }).error(function(data, status, headers, config) {
                        customer.rollback(patch.orig);
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });
                } else {
                    deferred.resolve(customer);
                }

                return deferred.promise;
            };

            instance.create = function() {
                return new Customer();
            };

            instance.find = function(term) {
                return $http.get('/api/customers', { params: { q: term, page: 1, subset: 'all', n: 20 } })
                    .then(function(response) {
                        return response.data.page.items || [];
                    });
            };

            return instance;
        }
    ]);

}(angular));
