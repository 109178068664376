(function(ng) {
    "use strict";
    var module = ng.module('app.surveyors.controllers', [
        'ui.router', 'ui.bootstrap', 'app.lib', 'app.controllers', 'app.navigation', 'app.surveyors.services'
    ]);

    module.config([
        '$stateProvider',
        function($stateProvider) {
            $stateProvider
                .state('surveyors', {
                    url: '/surveyors',
                    templateUrl: '/templates/surveyors/index.html',
                    controller: 'SurveyorsCtrl'
                })
                .state('surveyor', {
                    url: '/surveyor/{id:[0-9]+}',
                    templateUrl: '/templates/surveyors/detail.html',
                    controller: 'SurveyorCtrl'
                });
        }
    ]);

    module.controller('SurveyorsCtrl', [
        '$scope', '$state', '$modal', 'lodash', 'Watcher', 'surveyorService',
        function($scope, $state, $modal, lodash, Watcher, surveyorService) {
            $scope.model = {
                hits: surveyorService.hits
            };

            $scope.busyWatcher = new Watcher();

            $scope.$on('updated.surveyor', function(eventName, event) {
                var data = event.data;
                ng.forEach($scope.model.hits, function(s) {
                    if (s.id === data.id) {
                        lodash.assign(s, data);
                    }
                });
            });

            $scope.$on('created.surveyor', function(event, data) {
                $scope.model.hits.push(data);
            });


            $scope.create = function() {
                var promise = $modal.open({
                    templateUrl: '/templates/surveyors/create.html',
                    controller: 'CreateEntityCtrl',
                    resolve: {
                        entity: surveyorService.create
                    }
                }).result.then(function(surveyor) {
                    $scope.busyWatcher.begin();
                    surveyorService.post(surveyor)
                        .then(function(surveyor) {
                            $state.go('surveyor', { id: surveyor.id });
                        }).catch(function(reason) {
                            $scope.$emit('toastMessage', { text: reason, level: 'error' });
                        }).finally(function() {
                            $scope.busyWatcher.end();
                        });
                });
            };

            (function() {
                $scope.busyWatcher.begin(0);
                surveyorService.index()
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            }());
        }
    ]);

    module.controller('SurveyorCtrl', [
        '$scope', '$stateParams', 'Watcher', 'surveyorService',
        function($scope, $stateParams, Watcher, surveyorService) {
            $scope.model = {
                surveyor: null
            };

            $scope.busyWatcher = new Watcher();

            $scope.save = function() {
                $scope.busyWatcher.begin();
                surveyorService.patch($scope.model.surveyor)
                    .catch(function(reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            };

            $scope.$on('updated.surveyor', function(eventName, event) {
                var surveyor = $scope.model.surveyor,
                    data = event.data;
                if (surveyor && data.id === surveyor.id) {
//                    if (surveyor.isEditing()) {
//                        $scope.todo();
//                    } else {
                        surveyor.update(data);
//                        $scope.$emit('toastMessage', { text: 'This record has been updated by ' + event.username + '.', level: 'info' })
//                    }
                }
            });

            (function() {
                $scope.busyWatcher.begin();
                surveyorService.get($stateParams.id)
                    .then(function(data) {
                        $scope.model.surveyor = data;
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            }());
        }
    ]);

}(angular));
