(function (ng) {
    "use strict";

    var module = ng.module('app.accounts.services', ['app.lib', 'app.navigation', 'app.accounts.models']);

    module.factory('creditApplications', [
        '$http', '$q', 'lib',
        function($http, $q, lib) {
            var instance = {};

            instance.post = function(applications) {
                var d = $q.defer();

                $http.post('/api/accounts/credit-applications', { applications: applications })
                    .success(function(data) {
                        d.resolve(data.applications);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.patch = function(applicationId, diff) {
                var d = $q.defer();

                    $http({
                        method: 'PATCH',
                        url: '/api/surveyors/credit-applications/' + applicationId.toString(),
                        data: diff
                    })
                    .success(function(diff) {
                        d.resolve(diff);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.rebalance = function(diffs) {
                var d = $q.defer();

                $http.post('/api/accounts/credit-applications/rebalance', diffs)
                    .success(function(data) {
                        d.resolve({ deletions: data.deletions, diffs: data.diffs });
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.delete = function(applicationIds) {
                var d = $q.defer();

                $http.delete('/api/accounts/credit-applications/' + applicationIds[0].toString(), { ids: applicationIds })
                    .success(function(data) {
                        d.resolve(data.applicationIds);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            return instance;
        }
    ]);

    module.factory('credits', [
        '$http', '$q', 'lib',
        function($http, $q, lib) {
            var instance = {};

            instance.undeposited = function() {
                var d = $q.defer();

                $http.get('/api/accounts/credits/undeposited')
                    .success(function(data) {
                        d.resolve(data.credits);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.available = function(customerId) {
                var d = $q.defer();

                $http.get('/api/accounts/credits/available/' + customerId.toString())
                    .success(function(data) {
                        d.resolve(data.credits);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.post = function(credit, applications) {
                var d = $q.defer();

                $http.post('/api/accounts/credits', { credit: credit, applications: applications })
                    .success(function(data) {
                        d.resolve(data.applications);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.refund = function(jobId, refund, credit) {
                var d = $q.defer();
                var credits = credit ? [refund, credit] : [refund];

                $http.post('/api/accounts/credits', { jobId: jobId, credits: credits })
                    .success(function(data) {
                        d.resolve(data.applications);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            return instance;
        }
    ]);

    module.factory('invoices', [
        '$http', '$q', 'lib', 'accountsModels',
        function($http, $q, lib, accountsModels) {
            var instance = {};

            instance.index = function(customerId) {
                var d = $q.defer();

                $http.get('/api/accounts/invoices', { params: {customerId: customerId} })
                    .success(function (data) {
                        data.page.items = data.page.items || [];
                        for (var i=0; i < data.page.items.length; i++) {
                            data.page.items[i] = new accountsModels.Invoice(data.page.items[i]);
                        }
                        d.resolve(data.page);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            return instance;
        }
    ]);

    module.factory('paymentMethods', [
        '$http', '$q', 'lib', 'accountsModels',
        function($http, $q, lib, accountsModels) {
            var instance = {
                _methods: [],
                CREDIT: 3
            };

            instance.index = function() {
                var d = $q.defer();

                if (instance._methods.length) {
                    d.resolve(instance._methods);
                } else {
                    $http.get('/api/accounts/payment-methods')
                        .success(function(data) {
                            ng.forEach(data.paymentMethods, function(method) {
                                instance._methods.push(new accountsModels.PaymentMethod(method));
                            });
                            d.resolve(instance._methods);
                        })
                        .error(lib.util.httpError(d));
                }

                return d.promise;
            };

            return instance;
        }
    ]);

    module.factory('deposits', [
        '$http', '$q', 'lib', 'accountsModels', 'Pager',
        function ($http, $q, lib, accountsModels, Pager) {
            var instance = {
                page: new Pager()
            };

            instance.index = function(page) {
                var d = $q.defer();
                var params = {
                    page: page || 1
                };

                $http.get('/api/accounts/deposits', { params: params })
                    .success(function(data) {
                        instance.page.update(data.page);
                        d.resolve(data.page);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.get = function(id) {
                var deferred = $q.defer();

                $http.get('/api/accounts/deposits/' + id.toString())
                    .success(function(response) {
                        deferred.resolve(new accountsModels.Deposit(response.deposit));
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.post = function(txDate, creditIds) {
                var deferred = $q.defer();

                $http.post('/api/accounts/deposits', { txdate: txDate, credits: creditIds })
                    .success(function(response) {
                        deferred.resolve(response.id);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            return instance;
        }
    ]);

    module.factory('statements', [
        '$http', '$q', 'lib', 'accountsModels', 'Pager',
        function ($http, $q, lib, accountsModels, Pager) {
            var instance = {
                page: new Pager()
            };

            instance.page.itemCallback = function(item) {
                item.lastBilled = lib.moment(item.lastBilled);
                return item;
            };

            instance.index = function(page, subset, q) {
                var d = $q.defer();
                var params = {
                    page: page || 1,
                    subset: subset || 'all'
                };
                if (q) {
                    params.q = q;
                }

                $http.get('/api/accounts/statements', { params: params })
                    .success(function(data) {
                        instance.page.update(data.page);
                        d.resolve(data.page);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.get = function(id) {
                var deferred = $q.defer();

                $http.get('/api/accounts/statements/' + id.toString())
                    .success(function(response) {
                        deferred.resolve(new accountsModels.Statement(response.statement));
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            return instance;
        }
    ]);

    module.factory('aging', [
        '$http', '$q', 'lib', 'accountsModels',
        function ($http, $q, lib, accountsModels) {
            var instance = {
                report: new accountsModels.AgingReport()
            };

            instance.index = function(start, through, inactive) {
                var d = $q.defer();

                var params = {
                    start: start.format('YYYY-MM-DD'),
                    through: through.format('YYYY-MM-DD'),
                    inactive: inactive
                };

                $http.get('/api/accounts/aging', { params: params })
                    .success(function(response) {
                        var report = new accountsModels.AgingReport(response.report);
                        instance.report.update(response.report);
                        d.resolve(instance.report);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            return instance;
        }
    ]);


}(angular));
