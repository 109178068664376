(function(ng) {
    "use strict";

    var module = ng.module('app.navigation', ['app.lib']);

    module.factory('backService', [
        '$state', '$rootScope', '$log', 'lodash',
        function($state, $rootScope, $log, lodash) {
            var instance = {
                    stack: [],
                    index: -1
                },
                _restarts = {
                    'accounts.deposits': true,
                    'accounts.statements': true,
                    'accounts.deposits.page': true,
                    'accounts.statements.page': true,
                    'jobs.subset.page': true,
                    'jobs.subset.search': true,
                    'customers.subset.page': true,
                    'customers.subset.search': true,
                    'surveyors': true,
                    'users.page': true
                },
                isSame = function(target, state, stateParams) {
                    return (target.to === state.name) && lodash.isEqual(target.params, stateParams);
                };

            $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                $log.log('$stateChangeSuccess: ' + toState.name);
                var downOne = instance.index + 1;
                if (_restarts[toState.name]) {
                    instance.restart({
                            to: toState.name,
                            params: ng.copy(toParams)
                        });
                } else {
                    // if this is a 'drill down' append
                    if (!(downOne < instance.stack.length && isSame(instance.stack[downOne], fromState, fromParams))) {
                        instance.push({
                            to: toState.name,
                            params: ng.copy(toParams)
                        });
                    }
                }
            });

            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                $log.log('$stateChangeStart: ' + fromState.name + ' --> ' + toState.name);
            });
            $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
                $log.log('$stateChangeError: ' + fromState.name + ' --> ' + toState.name + ' > ' + ng.toJson(error));
            });
            $rootScope.$on('$stateNotFound', function(event, unfoundState, fromState, fromParams) {
                $log.log('$stateNotFound: ' + fromState.name + ' --> ' + unfoundState.to + '(' + ng.toJson(unfoundState.toParams) + ')');
            });

            instance.push = function(go) {
                if (ng.isString(go)) {
                    instance.push({
                        to: $state.current.name,
                        params: ng.copy($state.params),
                        label: go
                    });
                } else {
                    go.href = $state.href(go.to, go.params);
                    instance.index += 1;
                    instance.stack[instance.index] = go;
                }
            };

            instance.pop = function() {
                var response = null;
                instance.index -= 1;
                if (instance.index > -1) {
                    response = instance.stack[instance.index];
                } else {
                    instance.index = -1;
                }
                return response;
            };

            instance.restart = function(go) {
                instance.stack.length = 0;
                instance.index = -1;
                instance.push(go);
            };

            instance.goBack = function(defaultBack) {
                var back = instance.pop() || defaultBack;
                if (back) $state.go(back.to, back.params);
            };

            return instance;
        }
    ]);

    module.directive('backButton', ['backService', function(backService) {
        return {
            restrict: 'E',
            scope: {
                to: '@',
                params: '@'
            },
            template: '<button type="button" class="btn btn-success btn-back" title="Back"><i class="fa fa-level-up fa-rotate-270"></i> Back</button>',
            link: function(scope, element, attrs) {
                var params = scope.$eval(scope.params);
                element.bind('click', function() { backService.goBack({to: scope.to, params: params}); });
            }
        }
    }]);


    module.directive('searchBox', function() {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                'onSearch': '&',
                'searchTerm': '='
            },
            templateUrl: '/templates/common/search.html'
        };
    });

    module.directive('onEnterKey', ['$parse', function($parse) {
        return {
            restrict: 'A',
            compile: function(element, attrs) {
                var fn = $parse(attrs['onEnterKey']);
                return function(scope, element, attrs) {
                    element.on('keyup', function(event) {
                        if ((event.keyCode || event.which) === 13) {
                            scope.$apply(function() { fn(scope); });
                        }
                    });
                };
            }
        };
    }]);

    module.factory('Pager', ['lodash', function(lodash) {
        var DEFAULT_ITEMS_PER_PAGE = 10;

        function update(self, p) {
            self.pageNumber = p.pageNumber;
            self.totalItems = p.totalItems;
            self.itemsPerPage = p.itemsPerPage || DEFAULT_ITEMS_PER_PAGE;
            self.items = lodash.map(p.items || [], self.itemCallback, self);
        }

        function Pager(itemCallback, page) {
            this.pageNumber = 1;
            this.totalItems = 0;
            this.itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
            this.maxSize = 10;
            this.items = [];
            this.itemCallback = itemCallback || lodash.identity;
            if (page) {
                update(this, page);
            }
        }

        Pager.prototype.update = function(newPage) {
            return update(this, newPage);
        };

        Pager.prototype.item = function(callback) {
            return lodash.find(this.items, callback);
        };

        return Pager;
    }]);

    module.factory('PagedService', [
        '$http', '$q', 'lib', 'Pager',
        function($http, $q, lib, Pager) {
            function PagedService(url, options) {
                this.url = url;
                this.page = new Pager(options.pageItemCallback || lib.lodash.identity);
                this.getItemCallback = options.getItemCallback || lib.lodash.identity;
                this.errorCallback = options.errorCallback || lib.util.httpError;
            }

            PagedService.prototype.index = function(params, options) {
                var self = this,
                    d = $q.defer(),
                    errorCallback = options ? options.errorCallback || this.errorCallback : this.errorCallback;

                params.page = params.page || 1;

                $http.get(this.url, { params: params })
                    .success(function(data) {
                        self.page.update(data.page);
                        d.resolve(data.page);
                    })
                    .error(errorCallback(d));

                return d.promise;
            };

            PagedService.prototype.get = function(id, options) {
                var deferred = $q.defer(),
                    getItemCallback = options ? options.getItemCallback || this.getItemCallback : this.getItemCallback,
                    errorCallback = options ? options.errorCallback || this.errorCallback : this.errorCallback;

                $http.get(this.url + '/' + id.toString())
                    .success(function(response) {
                        deferred.resolve(getItemCallback(response.entity));
                    })
                    .error(errorCallback(deferred));

                return deferred.promise;
            };

            PagedService.prototype.post = function(data, options) {
                var deferred = $q.defer(),
                    errorCallback = options ? options.errorCallback || this.errorCallback : this.errorCallback;

                $http.post(this.url, data)
                    .success(function(response) {
                        deferred.resolve(response.id);
                    })
                    .error(errorCallback(deferred));

                return deferred.promise;
            };

            PagedService.prototype.patch = function(entityId, diff, options) {
                var d = $q.defer(),
                    errorCallback = options ? options.errorCallback || this.errorCallback : this.errorCallback;

                $http({
                        method: 'PATCH',
                        url: this.url + '/' + entityId.toString(),
                        data: diff
                    })
                    .success(function(data) {
                        d.resolve(data.diff);
                    })
                    .error(errorCallback(d));

                return d.promise;
            };

            return PagedService;
        }
    ]);

    // Allows you to show a "loading" or "busy" status with a slight
    // build-in delay
    module.factory('Watcher', ['$timeout', function($timeout) {

        function Watcher() {
            this._busy = this.isBusy = false;
        }

        Watcher.prototype.begin = function(delay) {
            var self = this;
            self._busy = true;
            if (delay === 0) {
                self.isBusy = true;
            } else {
                $timeout(function() {
                    self.isBusy = self._busy;
                }, delay || 1000);
            }
        };

        Watcher.prototype.end = function() {
            this._busy = this.isBusy = false;
        };

        Watcher.installOn = function($scope) {
            $scope.busyWatcher = new Watcher();

            $scope.$on('busy.begin', function (event, promise) {
                event.stopPropagation();
                $scope.busyWatcher.begin();
                if (promise) {
                    promise.finally(function() {
                        $scope.busyWatcher.end();
                    });
                }
            });

            $scope.$on('busy.end', function (event) {
                $scope.busyWatcher.end();
                event.stopPropagation();
            });
        };

        return (Watcher);
    }]);

}(angular));
