(function (ng) {
    "use strict";
    var module = ng.module('app.auth.services', ['app.lib', 'app.navigation', 'app.auth.models']);

    module.factory('users', [
        '$http', '$q', 'PagedService', 'authModels',
        function($http, $q, PagedService, authModels) {
            var instance = new PagedService('/api/auth/users',
                {
                    pageItemCallback: authModels.Account.create,
                    getItemCallback: authModels.Account.create
                });

            instance.changePassword = function(accountId, password, options) {
                var d = $q.defer(),
                    errorCallback = options ? options.errorCallback || instance.errorCallback : instance.errorCallback;

                $http.post(instance.url + '/' + accountId.toString() + '/password', { password: password })
                    .success(function() {
                        d.resolve(true);
                    })
                    .error(errorCallback(d));

                return d.promise;
            };

            return instance;
        }
    ]);

}(angular));
