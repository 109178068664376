(function (ng) {
    "use strict";
    var ADMIN = 'Administrator';
    var _listenForAdmin = function($scope, lodash) {
        $scope.$watch('model.userIsAdmin', function(newVal, oldVal) {
            var user = $scope.model.user;
            if ((newVal !== oldVal) && user) {
                var index = lodash.indexOf(user.roleNames, ADMIN);
                if (newVal) {
                    if (index < 0) {
                        user.roleNames.push(ADMIN);
                    }
                } else {
                    if (index > -1) {
                        user.roleNames.splice(index, 1);
                    }
                }
            }
        });

        $scope.$watchCollection('model.user.roleNames', function() {
            var user = $scope.model.user;
            $scope.model.userIsAdmin = user && user.isInRole(ADMIN);
        });
    };

    var module = ng.module('app.auth.controllers', [
        'ui.router', 'ui.bootstrap', 'app.auth', 'app.lib', 'app.directives', 'app.navigation',
        'app.auth.models', 'app.auth.services'
    ]);

    module.config([
        '$stateProvider', '$urlRouterProvider',
        function($stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/users', '/users/all/1');

            $stateProvider
                .state('users', {
                    url: '/users',
                    template: '<ui-view/>',
                    resolve: {
                        Watcher: 'Watcher'
                    },
                    controller: [
                        '$scope', 'Watcher',
                        function($scope, Watcher) {
                            Watcher.installOn($scope);
                        }
                    ]
                })
                .state('users.page', {
                    url: '/all/{page:[0-9]+}',
                    templateUrl: 'templates/auth/users-page.html',
                    controller: 'UsersCtrl'
                })
                .state('users.user', {
                    url: '/{userId:[0-9]+}',
                    templateUrl: 'templates/auth/user.html',
                    controller: 'UserCtrl'
                });
        }
    ]);

    module.controller('UsersCtrl', [
        '$scope', '$state', '$stateParams', '$modal', 'lib', 'userService', 'users',
        function($scope, $state, $stateParams, $modal, lib, userService, users) {
            $scope.model = {
                currentUser: userService,
                page: users.page
            };

            $scope.commands = {
                page: function(pageNumber) {
                    $state.go('users.page', {page: pageNumber});
                },
                create: function() {
                    var promise = $modal.open({
                        backdrop: 'static',
                        templateUrl: 'templates/auth/user-create-modal.html',
                        controller: [
                            '$scope', 'lib', 'authModels',
                            function($scope, lib, authModels) {
                                $scope.model = {
                                    user: authModels.Account.new(),
                                    userIsAdmin: false
                                };
                                _listenForAdmin($scope, lib.lodash);
                            }
                        ]
                    }).result;

                    promise.then(function(entity) {
                        users.post(entity)
                            .then(function(id) {
                                $state.go('users.user', {userId: id});
                            })
                            .catch(lib.util.emitError($scope));
                    });
                }
            };

            (function() {
                $scope.$emit('busy.begin', users.index({ page: $stateParams.page }));
            }());
        }
    ]);

    module.controller('UserCtrl', [
        '$scope', '$stateParams', '$modal', 'lib', 'userService', 'users',
        function($scope, $stateParams, $modal, lib, userService, users) {
            var _locals = {};
            $scope.model = {
                currentUser: userService,
                user: null,
                userIsAdmin: false
            };

            $scope.model.template = function() {
                return ($scope.model.user && $scope.model.user.isEditing()) ?
                    'templates/auth/user-edit.html' : 'templates/auth/user-view.html';
            };

            $scope.model.isSelf = function() {
                return $scope.model.user && ($scope.model.user.username === userService.username);
            };

            _locals.saveDiff = function(user, delta) {
                if (delta.diff) {
                    $scope.$emit('busy.begin',
                        users.patch(user.id, delta.diff)
                            .catch(function(reason) {
                                user.rollback(delta.orig);
                                $scope.$emit('toastMessage',
                                    {
                                        text: reason,
                                        level: 'error'
                                    });
                            })
                    );
                }
            };

            $scope.commands = {
                setActive: function(active) {
                    var user = $scope.model.user;
                    user.edit();
                    user.active = active;
                    _locals.saveDiff(user, user.save());
                },

                changePassword: function() {
                    var user = $scope.model.user;
                    var promise = $modal.open({
                        backdrop: 'static',
                        templateUrl: 'templates/auth/change-password-modal.html'
                    }).result;

                    promise.then(function(password) {
                        users.changePassword(user.id, password)
                            .catch(lib.util.emitError($scope));
                    });
                },

                save: function() {
                    var user = $scope.model.user;
                    _locals.saveDiff(user, user.save());
                },

                setUser: function(user) {
                    $scope.model.user = user;
                }
            };

            _listenForAdmin($scope, lib.lodash);

            (function() {
                var id = Number($stateParams.userId),
                    user = users.page.item({id: id});

                if (user) {
                    $scope.commands.setUser(user);
                } else {
                    users.get(id)
                        .then(function(entity) {
                            $scope.commands.setUser(entity);
                        })
                        .catch(lib.util.emitError($scope));
                }
            }());
        }
     ]);

}(angular));
