(function (ng) {
    "use strict";
    var module = ng.module('app.auth.models',
        ['app.services']);

    module.factory('authModels', [
        'lib', 'inherit', 'EditableEntity',
        function(lib, inherit, EditableEntity) {

            function Account(json) {
                EditableEntity.call(this, json);
                this.roleNames = this.roleNames || [];
            }
            inherit(Account, EditableEntity);
            Account.prototype.edit = function() {
                this._orig = lib.lodash.cloneDeep(this);
                return this;
            };
            Account.prototype.isInRole = function(roleOrArray) {
                var reply = false,
                    lodash = lib.lodash;
                if (lodash.isArray(roleOrArray)) {
                    reply = lodash.any(roleOrArray, function(r) {
                        lodash.contains(this.roleNames, r);
                    });
                } else {
                    reply = lodash.contains(this.roleNames, roleOrArray);
                }
                return reply;
            };

            Account.create = function(json) {
                return new Account(json);
            };

            Account.new = function() {
                return new Account({
                    active: true,
                    username: null,
                    password: null,
                    displayName: null,
                    email: null,
                    roleNames: []
                });
            };

            return {
                Account: Account
            }
        }
    ]);
}(angular));
