(function(ng) {
    "use strict";

    var module = ng.module('app.controllers', ['ui.bootstrap', 'app.auth', 'app.services', 'app.directives']);

    module.controller('MainCtrl', [
        '$scope', '$timeout', 'userService',
        function($scope, $timeout, userService) {
            $scope.user = userService;

            $scope.alerts = {
                items: []
            };

            function toast(alert) {
                alert.template = alert.template || '/templates/common/alert-content.html';
                alert.type = 'alert-' + alert.level;
                if (alert.type === 'alert-error') {
                    alert.type = 'alert-danger';
                }

                $scope.alerts.items.push(alert);
                if (alert.timeout !== 0) {
                    $timeout(function () {
                        var idx = $scope.alerts.items.indexOf(alert);
                        if (idx > -1) {
                            $scope.alerts.closeAlert(idx);
                        }
                    }, alert.timeout || 5000);
                }
            }

            function closeAlert(alert) {
                alert.isCollapsed = true;
                $timeout(function() {
                    var idx = $scope.alerts.items.indexOf(alert);
                    if (idx > -1) {
                        $scope.alerts.items.splice(idx, 1);
                    }
                }, 500);
            }

            $scope.alerts.closeAlert = function(idx) {
                var alert = $scope.alerts.items[idx];
                closeAlert(alert);
            };

            $scope.alerts.runCommand = function(idx, command) {
                $scope.alerts.closeAlert(idx);
                command.execute();
            };

            $scope.$on('toastMessage', function(event, data) {
                if (ng.isString(data)) {
                    toast({ text: data, type: 'info' });
                } else {
                    toast(data);
                }
            });

            userService.load();
        }
    ]);

    module.controller('MenuCtrl', [
        '$scope', '$timeout', 'userService',
        function($scope, $timeout, userService) {
        }
    ]);

    module.controller('CreateEntityCtrl', [
        '$scope', '$modalInstance', 'addressLookup', 'entity',
        function($scope, $modalInstance, addressLookup, entity) {
            $scope.entity = entity;

            $scope.locations = addressLookup.locations;

            $scope.ok = function() {
                $modalInstance.close($scope.entity);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss();
            };

            (function() {
                $scope.entity.edit();
            }());
        }
    ]);

    module.controller('SelectLabelCtrl', [
        '$scope', '$modalInstance', 'exclude', 'title', 'help', 'searchFunction',
        function($scope, $modalInstance, exclude, title, help, searchFunction) {
            $scope.entity = null;
            $scope.searchTerm = '';
            $scope.exclude = exclude || 0;
            $scope.title = title || 'Select';
            $scope.help = help;

            $scope.items = searchFunction;

            $scope.select = function($item, $model, $label) {
                $scope.entity = $item;
            };
        }
    ]);

})(angular);
