(function (ng) {
    "use strict";
    var module = ng.module('app.accounts.models',
        ['app.services']);

    module.factory('accountsModels', [
        'lib', 'inherit', 'JsonObject',
        function(lib, inherit, JsonObject) {

            function Deposit(json) {
                JsonObject.call(this, json);
                this.depositDate = lib.moment(json.depositDate);
                this.credits = lib.lodash.map(json.credits, function(c) { return new Credit(c); });

                this.total = lib.lodash.reduce(this.credits, function(sum, credit) {
                    return sum + credit.amount;
                }, 0);
            }
            inherit(Deposit, JsonObject);

            function Statement(json) {
                JsonObject.call(this, json);
                this.lastBilled = lib.moment(json.lastBilled);
                this.lineItems = ng.forEach(json.lineItems, function(i) {
                    i.billedOn = lib.moment(i.billedOn);
                    return i;
                });
            }
            inherit(Statement, JsonObject);

            function Credit(json) {
                JsonObject.call(this, json);
                this.txDate = lib.moment(json.txDate);
            }
            inherit(Credit, JsonObject);

            function AvailableCredit(json) {
                JsonObject.call(this, json);
                this.txDate = lib.moment(json.txDate);
            }
            inherit(AvailableCredit, JsonObject);

            function AppliableCredit(credit) {
                JsonObject.call(this, credit);
                this.applications = [];
            };
            inherit(AppliableCredit, JsonObject);
            AppliableCredit.prototype.applyTo = function(jobId, balance) {
                var applied = Math.min(balance, this.available);
                this.available -= applied;

                this.applications.push({ apply: applied, creditId: this.id, jobId: jobId});
                return applied;
            };

            AppliableCredit.prototype.clearApplications = function() {
                var apps = this.applications.splice(0, this.applications.length);
                var unapplied = 0;
                ng.forEach(apps, function(app) {
                    unapplied += app.apply;
                });
                this.available += unapplied;
                return unapplied;
            };

            function PaymentMethod(json) {
                JsonObject.call(this, json);
            }
            inherit(PaymentMethod, JsonObject);

            function Invoice(json) {
                JsonObject.call(this, json);

                this.charged = json.charged || 0;
                this.paid = json.paid || 0;
                this.balance = this.charged - this.paid;
                if (json.billedOn) {
                    this.billedOn = lib.moment(json.billedOn);
                }
                if (json.cancelled) {
                    this.cancelled = lib.moment(json.cancelled);
                }
            }
            inherit(Invoice, JsonObject);

            function Appliable() {
            }
            Appliable.prototype.apply = function(amount) {
                this.applied = Math.min(amount, this.balance);
                this.checked = this.applied != 0;
                return this.applied;
            };
            Appliable.prototype.unapply = function() {
                var unapplied = this.applied;
                this.applied = 0;
                this.checked = false;
                return unapplied;
            };

            function PayableInvoice(invoice) {
                JsonObject.call(this, invoice);
                this.applied = 0;
            }
            inherit(PayableInvoice, [JsonObject, Appliable]);

            function AppliedCredit(json) {
                Credit.call(this, json);
                this.applied = 0;
                this.balance = this.amount;
            }
            inherit(AppliedCredit, [Credit, Appliable]);

            function AgingReport(json) {
                JsonObject.call(this, json || { totals: {}, lineItems: [] });
            }
            AgingReport.prototype.beforeUpdate = function(json) {
                json.totals = ng.extend({
                    credit: 0,
                    zero: 0,
                    thirty: 0,
                    sixty: 0,
                    ninety: 0
                }, json.totals || {});
                json.lineItems = ng.forEach(json.lineItems || [], function(i) {
                        i = ng.extend({
                        credit: 0,
                        zero: 0,
                        thirty: 0,
                        sixty: 0,
                        ninety: 0
                    }, i);
                    i.billedOn = lib.moment(i.billedOn);
                    return i;
                });
            };
            inherit(AgingReport, JsonObject);


            return {
                Deposit: Deposit,
                Credit: Credit,
                AvailableCredit: AvailableCredit,
                AppliableCredit: AppliableCredit,
                AppliedCredit: AppliedCredit,
                PaymentMethod: PaymentMethod,
                Invoice: Invoice,
                PayableInvoice: PayableInvoice,
                Statement: Statement,
                AgingReport: AgingReport
            };
        }
    ]);


}(angular));
