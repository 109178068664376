(function(ng) {
    "use strict";
    var module = ng.module('app.jobs.controllers', [
        'ui.router', 'ui.bootstrap', 'app.auth', 'app.directives', 'app.navigation',
        'app.jobs.directives', 'app.jobs.services', 'app.accounts.controllers', 'app.accounts.services',
        'app.customers.controllers', 'app.services.services',
        'app.surveyors.services'
    ]);

    module.config([
        '$stateProvider',
        function($stateProvider) {
            $stateProvider
                .state('jobs', {
                    url: '/jobs',
                    template: '<ui-view/>',
                    controller: 'JobsCtrl'
                })
                .state('jobs.subset', {
                    url: '/{subset}',
                    templateUrl: '/templates/jobs/index.html',
                    controller: 'JobsSubsetCtrl',
                    abstract: true
                })
                .state('jobs.subset.search', {
                    url: '/{page:[0-9]+}?term&file&cust&street&district&subdivision&lot&square',
                    templateUrl: '/templates/jobs/list.html',
                    controller: 'JobListCtrl'
                })
                .state('jobs.subset.page', {
                    url: '/{page:[0-9]+}',
                    templateUrl: '/templates/jobs/list.html',
                    controller: 'JobListCtrl'
                })
                .state('jobs.details', {
                    url: '/{id:[0-9]+}',
                    template: '<ui-view/>',
                    abstract: true,
                    controller: 'JobCtrl',
                    resolve: {
                        'jobsService': 'jobsService',
                        job: ['$stateParams', 'jobsService', function($stateParams, jobsService) {
                            return jobsService.get($stateParams.id);
                        }]
                    }
                })
                .state('jobs.details.overview', {
                    url: '/overview',
                    templateUrl: '/templates/jobs/job.html',
                    controller: 'JobDetailsCtrl'
                })
                .state('jobs.details.elevation', {
                    url: '/elevation/{elevation:[0-9]+}',
                    templateUrl: '/templates/jobs/elevation.html',
                    controller: 'ElevationCtrl'
                });
        }
    ]);

    module.controller('JobsCtrl', [
        '$scope', '$state', '$q', '$modal', 'jobsService', 'customerService', 'userService',
        function($scope, $state, $q, $modal, jobsService, customerService, userService) {
            var REGEX_JOB_NUM = /^#\d+$/i;
            $scope.searchParams = {
                term: '',
                hideFilters: true
            };
            $scope.commands = {};

            $scope.searchParams.doSearch = function(term) {
                if (REGEX_JOB_NUM.test(term)) {
                    term = term.substr(1);
                    $state.go('jobs.details.overview', { id: term });
                } else {
                    term = term ? term.replace(/^\s+|\s+$/g, '') : '';
                    if (term || $scope.searchParams.hasFilter()) {
                        var args = $scope.searchParams.filters();
                        args.term = term;
                        args.page = 1;
                        $state.go('jobs.subset.search', args);
                    } else {
                        $state.go('jobs.subset.page', {page: 1});
                    }
                }
            };

            $scope.searchParams.hasFilter = function() {
                var p = $scope.searchParams;
                return p.file || p.cust || p.street || p.district
                    || p.subdivision || p.lot || p.square;
            };

            $scope.searchParams.filters = function() {
                var p = $scope.searchParams;
                return {
                    file: p.file,
                    cust: p.cust,
                    street: p.street,
                    district: p.district,
                    subdivision: p.subdivision,
                    lot: p.lot,
                    square: p.square
                };
            };

            $scope.searchParams.doFilter = function(file, cust, street, district, subdivision, lot, square) {
                var args = {
                    page: 1,
                    term: $scope.searchParams.term,
                    file: file,
                    cust: cust,
                    street: street,
                    district: district,
                    subdivision: subdivision,
                    lot: lot,
                    square: square
                };
                $scope.searchParams.hideFilters = true;
                $state.go('jobs.subset.search', args);
            };

            $scope.commands.create = function() {
                var saveCustomer = function(wizard) {
                    if (wizard.customer.id) return $q.when(wizard);

                    return customerService.post(wizard.customer).then(function(customer) {
                        wizard.customer = customer;
                        return $q.when(wizard);
                    });
                };

                var saveJob = function(wizard) {
                    return jobsService
                        .post(wizard.customer, wizard.elevation.address,
                            wizard.orderForm, wizard.elevation.label,
                            { latitude: wizard.elevation.latitude, longitude: wizard.elevation.longitude });
                };

                var goToJob = function(jobId) {
                    $state.go('jobs.details.overview', { id: jobId });
                };

                var promise = $modal.open({
                    template: '<div ng-include="templateName"></div>',
                    backdrop: 'static',
                    controller: 'JobWizardCtrl',
                    size: 'lg'
                }).result;

                promise.then(saveCustomer).then(saveJob).then(goToJob).catch(function(reason) {
                    if (reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    }
                });
            };

            $scope.counts = {};

            jobsService.counts().then(function(response) {
                $scope.counts = response.data;
            });

            $scope.$on('jobs.counts.updated', function(event, args, direction) {
                angular.extend($scope.counts, args.data);
                if ('up' === direction) {
                    event.stopPropagation();
                }
            });

            $scope.$on('job.touch', function(event, job) {
                event.stopPropagation();
                job.touch(userService.username);
            });
        }
    ]);

    module.controller('JobWizardCtrl', [
        '$scope', 'addressLookup', 'customerService',
        function($scope, addressLookup, customerService) {
            var _locals = {
                resolve: $scope.$close
            };
            $scope.wizard = {
                customer: null,
                orderForm: null,
                elevation: null
            };

            $scope.locations = addressLookup.locations;
            $scope.addresses = addressLookup.addresses;

            // select or create customer
            $scope.title = $scope.title || 'Customer'
            $scope.entity = null;
            $scope.templateName = '/templates/customers/select.html';

            $scope.searchModel = {
                searchTerm: ''
            };
            $scope.items = customerService.find;
            $scope.selectSuggestion = function($item) { //}, $model, $label) {
                $scope.entity = $item;
            };

            $scope.$watch('searchModel.searchTerm', function(newValue) {
                if ($scope.entity && newValue !== $scope.entity.label) {
                    $scope.entity = null;
                }
            });

            // create new customer
            $scope.create = function(name) {
                var entity = customerService.create();
                entity.edit();
                entity.label = name;
                $scope.entity = entity;
                $scope.templateName = '/templates/customers/create.html';
            };

            _locals.okCustomer = function(customer) {
                $scope.wizard.customer = customer;
                $scope.orderForm = {
                    orderedBy: customer.contactName,
                    orderedByPhone: customer.phone
                };
                // advance to order form
                $scope.templateName = '/templates/jobs/order-form-modal.html';

                $scope.ok = _locals.okOrderForm;
            };

            _locals.okOrderForm = function(orderForm) {
                $scope.wizard.orderForm = orderForm;

                $scope.label = '';
                $scope.latitude = null;
                $scope.longtude = null;
                $scope.address = {};

                $scope.onAddressSelect = function(address) {
                    var copy = ng.copy(address);
                    delete copy.full;
                    delete copy.latitude;
                    delete copy.longitude;

                    $scope.latitude = address.latitude;
                    $scope.longitude = address.longitude;
                    ng.extend($scope.address, copy);
                };

                $scope.templateName = '/templates/jobs/add-elevation-modal-short.html';
                $scope.$close = _locals.okElevation;
            };

            // create initial elevation
            _locals.okElevation = function(elevation) {
                $scope.wizard.elevation = elevation;
                _locals.resolve($scope.wizard);
            };

            // initial state
            $scope.ok = _locals.okCustomer;
        }
    ]);

    module.controller('JobsSubsetCtrl', [
        '$scope', '$state', '$stateParams', 'jobsService',
        function($scope, $state, $stateParams, jobsService) {
            $scope.subset = {
                name: $stateParams.subset,
                classFor: function(subset) {
                    return subset === $stateParams.subset ? 'btn-primary' : 'btn-default';
                }
            };

            $scope.toggleStar = function(item) {
                jobsService.toggleStar(item).then(function(response) {
                    $scope.$emit('jobs.counts.updated', response, 'up');
                });
            };

            $scope.onSelectPage = function(pageNumber) {
                $state.go($state.current.name, {page: pageNumber});
            };
        }
    ]);

    module.controller('JobListCtrl', [
        '$scope', '$state', '$stateParams', 'jobsService', 'Watcher',
        function($scope, $state, $stateParams, jobsService, Watcher) {
            $scope.model = {
                page: jobsService.page
            };

            $scope.busyWatcher = new Watcher();
            $scope.searchParams.term = $stateParams.term;
            $scope.searchParams.cust = $stateParams.cust;
            $scope.searchParams.street = $stateParams.street;
            $scope.searchParams.district = $stateParams.district;
            $scope.searchParams.subdivision = $stateParams.subdivision;
            $scope.searchParams.lot = $stateParams.lot;
            $scope.searchParams.square = $stateParams.square;

            (function() {
                var search = ng.extend({}, $stateParams);
                delete search.page;
                delete search.term;
                $scope.busyWatcher.begin();
                jobsService.index($stateParams.page || 1, $scope.subset.name || 'active', $stateParams.term, search)
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            })();
        }
    ]);

    module.controller('JobCtrl', [
        '$scope', 'job',
        function($scope, job) {
            $scope.jobCtrlModel = {
                job: job
            };
        }
    ]);

    module.controller('JobDetailsCtrl', [
        '$scope', '$q', '$state', '$stateParams',
        '$modal', 'lib', 'job', 'jobsService', 'creditApplications', 'Comment', 'Watcher',
        function($scope, $q, $state, $stateParams,
                     $modal, lib, job, jobsService, creditApplications, Comment, Watcher) {
            $scope.model = {
                jobId: $stateParams.id,
                job: job
            };
            $scope.busyWatcher = new Watcher();

            $scope.$on('busy.begin', function (event, promise) {
                event.stopPropagation();
                $scope.busyWatcher.begin();
                if (promise) {
                    if (ng.isArray(promise)) {
                        promise = $q.all(promise);
                    }
                    promise.finally(function() {
                        $scope.busyWatcher.end();
                    });
                }
            });

            $scope.$on('busy.end', function (event) {
                $scope.busyWatcher.end();
                event.stopPropagation();
            });


            $scope.model.toggleStar = function() {
                jobsService.toggleStar($scope.model.job).then(function(response) {
                    $scope.$emit('jobs.counts.updated', response, 'up');
                });
            };

            function obtainComment(title, help) {
                var scope = $scope.$new(true);
                scope.title = title;
                scope.help = help;
                return $modal.open({
                    templateUrl: '/templates/jobs/add-comment.html',
                    scope: scope
                }).result;
            }

            function changeState(job, stateName, enable, reason) {
                return jobsService.state(job, stateName, enable, reason)
                    .then(function (results) {
                        ng.extend(job.orderForm, results.entity);
                        job.comments.push(results.comment);
                        $scope.$emit('job.touch', job);
                    })
                    .catch(function (reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    });
            }

            $scope.model.state = function(stateName, enable, options) {
                if (options.skipComment) {
                    changeState($scope.model.job, stateName, enable);
                } else {
                    obtainComment(options.title, options.help).then(function(reason) {
                        changeState($scope.model.job, stateName, enable, reason);
                    });
                }
            };

            function cancel(scope, job) {
                var call = function(reason) {
                    jobsService.cancel(job, reason)
                        .then(function(results) {
                            ng.extend(job.orderForm, results.entity);
                            job.comments.push(results.comment);
                            job.addPayments(results.applications);
                            scope.$emit('job.touch', job);
                        })
                        .catch(function (reason) {
                            scope.$emit('toastMessage', { text: reason, level: 'error' });
                        });
                };
                obtainComment('Cancel', 'Cancel job and stop work on it')
                    .then(call);
            }

            function uncancel(scope, job) {
                var modalScope = $scope.$new(true),
                    promise;
                modalScope.model = {
                    writtenOff: job.billingInfo.creditAmount - job.billingInfo.paidAmount,
                    reason: ''
                };
                modalScope.model.recapture = modalScope.model.writtenOff;
                promise = $modal.open({
                    backdrop: 'static',
                    scope: modalScope,
                    templateUrl: '/templates/jobs/uncancel-modal.html'
                }).result;

                promise.then(function(result) {
                    jobsService.uncancel(job, result.reason, result.recapture)
                        .then(function(results) {
                            ng.extend(job.orderForm, results.entity);
                            job.comments.push(results.comment);
                            job.addPayments(results.applications);
                            scope.$emit('job.touch', job);
                        })
                        .catch(function (reason) {
                            scope.$emit('toastMessage', { text: reason, level: 'error' });
                        });
                });
            }

            $scope.model.toggleCancelled = function() {
                var job = $scope.model.job;
                if (job.orderForm.cancelled) {
                    uncancel($scope, job);
                } else {
                    cancel($scope, job);
                }
            };

            $scope.model.hold = function() {
                $modal.open({
                    templateUrl: '/templates/jobs/place-hold.html'
                })
                .result
                .then(function(result) {
                    var job = $scope.model.job;
                    jobsService.hold(job, result.until, result.reason)
                        .then(function(results) {
                            ng.extend(job.orderForm, results.entity);
                            job.comments.push(results.comment);
                            $scope.$emit('job.touch', job);
                        })
                        .catch(function (reason) {
                            $scope.$emit('toastMessage', { text: reason, level: 'error' });
                        });
                });
            };

            $scope.commands = {};
            $scope.commands.applyCredits = function() {
                var promise = $modal.open({
                    templateUrl: '/templates/accounts/apply-credit-modal.html',
                    backdrop: 'static',
                    controller: 'ApplyCreditCtrl',
                    resolve: {
                        job: function() { return (job); },
                        availableCredits: function() { return (job.availableCredits); }
                    }
                }).result;

                promise.then(function(applications) {
                    creditApplications.post(applications)
                        .then(function(applications) {
                            job.addPayments(applications);
                            $scope.$emit('job.touch', job);
                        })
                        .catch(lib.util.emitError($scope));
                });
            };

            $scope.$on('updated.job', function(event, args) {
                if (args.data.id === $scope.model.job.id) {
                    var obj = ng.extend({}, args.data);
                    delete obj.id;
                    $scope.model.job.orderForm.update(obj);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.job-billing', function (event, args) {
                if (args.data.id === $scope.model.job.id) {
                    job.billingInfo.update({ billedOn: args.data.billedOn });
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('created.job-comment', function(event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    job.comments.push(new Comment(args.data));
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('created.credit-applications', function(event, args) {
                if (args.data.length && args.data[0].jobId === $scope.model.job.id) {
                    $scope.model.job.addPayments(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.credit-applications', function (event, args) {
                var job = $scope.model.job,
                    credit;
                ng.forEach(args.data, function(patch) {
                    if (job.id === patch.jobId) {
                        credit = lib.lodash.find(job.billingInfo.payments, {id: patch.id});
                        if (credit) {
                            ng.extend(credit, patch);
                            $scope.model.job.touch(args.username);
                        }
                    }
                });
            });

            $scope.$on('deleted.credit-applications', function (event, args) {
                var idx;
                ng.forEach(args.data, function(id) {
                    idx = lib.lodash.findIndex(job.billingInfo.payments, {id: id});
                    if (idx > -1) {
                        job.billingInfo.payments.splice(idx, 1);
                        $scope.model.job.touch(args.username);
                    }
                });
            });

            $scope.$on('created.job-service', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    $scope.model.job.billingInfo.services.push(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.job-service', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    var svc = lib.lodash.find($scope.model.job.billingInfo.services, {id: args.data.id});
                    if (svc) {
                        ng.extend(svc, args.data);
                        $scope.model.job.touch(args.username);
                    }
                }
            });

            $scope.$on('created.elevation', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    $scope.model.job.elevations.push(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.elevation', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    var el = lib.lodash.find($scope.model.job.elevations, {id: args.data.id});
                    if (el) {
                        ng.extend(el, args.data);
                        $scope.model.job.touch(args.username);
                    }
                }
            });

            (function() {
                $scope.$watch(function() {
                    return ng.toJson(job.billingInfo);
                }, function() { job._recalculate(); });
            })();
        }
    ]);

    module.controller('JobOrderFormCtrl', [
        '$scope', '$q', '$modal', 'jobsService', 'customerService',
        function($scope, $q, $modal, jobsService, customerService) {
            $scope.job = $scope.model.job;
            $scope.orderForm = $scope.model.job.orderForm;
            $scope.templateName = function() {
                return $scope.orderForm.isEditing() ? '/templates/jobs/orderForm-edit.html' : '/templates/jobs/orderForm-view.html';
            };

            $scope.save = function() {
                $scope.busyWatcher.begin();
                return jobsService.patchOrderForm($scope.orderForm)
                    .then(function() {
                        $scope.$emit('job.touch', $scope.job);
                    })
                    .catch(function(reason) {
                        $scope.$emit('toastMessage',
                            {
                                text: reason,
                                level: 'error'
                            });
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            };

            function xfer(job, customer, was) {
                jobsService.transferToCustomer(job, customer)
                    .then(function() {
                        if (was) {
                            $scope.$emit('toastMessage',
                                {
                                    text: 'Job customer changed.',
                                    level: 'info',
                                    commands: [
                                        {
                                            label: 'Undo',
                                            execute: function() {
                                                xfer($scope.job, was);
                                            }
                                        }
                                    ]
                                });
                            $scope.$emit('job.touch', job);
                        }
                    })
                    .catch(function(reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    });
            }

            $scope.transfer = function() {
                var was = $scope.orderForm.customer;
                var promise = $modal.open({
                    template: '<div ng-include="templateName"></div>',
                    backdrop: 'static',
                    controller: 'CustomerWizardCtrl'
                }).result;

                var saveCustomer = function(customer) {
                    if (customer.id) return $q.when(customer);

                    return customerService.post(customer);
                };

                var doXfer = function(customer) {
                    xfer($scope.job, customer, was);
                };

                promise.then(saveCustomer).then(doXfer).catch(function(reason) {
                    if (reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    }
                });
            };

            $scope.link = function(property) {
                var promise = $modal.open({
                    templateUrl: '/templates/common/select-label-modal.html',
                    controller: 'SelectLabelCtrl',
                    resolve: {
                        title: function() { return 'Link to...' },
                        help: ng.noop,
                        exclude: function() { return (property === 'followupToId') ? $scope.job.id : 0; },
                        searchFunction: function() { return jobsService.suggest; }
                    }
                }).result;

                promise.then(function(result) {
                    $scope.orderForm.edit();
                    $scope.orderForm[property] = result.id;
                    $scope.busyWatcher.begin();
                    $scope.save();
                });
            };

            $scope.unlink = function(property) {
                var was = $scope.orderForm[property];
                $scope.orderForm.edit();
                $scope.orderForm[property] = null;
                $scope.save()
                    .then(function() {
                        $scope.$emit('toastMessage',
                            {
                                text: 'Job unlinked.',
                                level: 'info',
                                commands: [
                                    {
                                        label: 'Undo',
                                        execute: function() {
                                            $scope.orderForm.edit();
                                            $scope.orderForm[property] = was;
                                            $scope.save();
                                        }
                                    }
                                ]
                            });
                    })
            };
        }
    ]);

    module.controller('JobElevationsCtrl', [
        '$scope', '$modal', '$state', 'jobsService', 'addressLookup',
        function($scope, $modal, $state, jobsService, addressLookup) {
            $scope.model = {
                job: $scope.model.job
            };

            $scope.add = function() {
                var modalScope = $scope.$new(true);
                modalScope.label = ($scope.model.job.elevations.length + 1).toString();
                modalScope.address = ng.extend({}, $scope.model.job.address);
                delete modalScope.address.id;
                modalScope.addresses = addressLookup.addresses;
                modalScope.onAddressSelect = function($item, $model, $label) {
                    modalScope.address = $item;
                };

                var promise = $modal.open({
                    templateUrl: '/templates/jobs/add-elevation-modal.html',
                    scope: modalScope
                }).result;

                promise.then(function(obj) {
                    $scope.busyWatcher.begin();
                    jobsService.createElevation($scope.model.job, obj.label, obj.address)
                        .then(function(elevation) {
                            $scope.$emit('job.touch', $scope.model.job);
                            $scope.model.job.elevations.push(elevation);
                            $state.go('jobs.details.elevation', { elevation: $scope.model.job.elevations.length });
                        }).catch(function(reason) {
                            $scope.$emit('toastMessage', { text: reason, level: 'error', timeout: 0 });
                        }).finally(function() {
                            $scope.busyWatcher.end();
                        });
                });
            };
        }
    ]);

    module.controller('JobCommentsCtrl', [
        '$scope', '$modal', 'jobsService',
        function($scope, $modal, jobsService) {
            $scope.model = {
                job: $scope.model.job
            };

            $scope.add = function() {
                var promise = $modal.open({
                    templateUrl: '/templates/jobs/add-comment.html'
                }).result;

                promise.then(function(comment) {
                    // save to server
                    jobsService.addComment($scope.model.job, comment)
                        .then(function(comment) {
                            $scope.model.job.comments.push(comment);
                            $scope.$emit('job.touch', $scope.model.job);
                        })
                        .catch(function(reason) {
                            $scope.$emit('toastMessage', { text: reason, level: 'error' });
                        });
                });

            };
        }
    ]);

    module.controller('JobServicesCtrl', [
        '$scope', '$modal', 'jobsService',
        function($scope, $modal, jobsService) {
            $scope.model = {
                job: $scope.model.job
            };

            $scope.service = function(service) {
                service = service || {
                    id: 0,
                    label: '',
                    quantity: null,
                    fee: null
                };
                var orig = ng.extend({}, service);
                var promise = $modal.open({
                    templateUrl: '/templates/jobs/service-modal.html',
                    controller: 'JobServiceCtrl',
                    resolve: {
                        service: function() { return service; },
                        billedOn: function() { return $scope.model.job.billingInfo.billedOn; }
                    }
                }).result;

                promise.then(function(result) {
                    var services = $scope.model.job.billingInfo.services;
                    if (result === 'delete') {
                        ng.extend(service, orig);
                        jobsService.deleteService($scope.model.job, service)
                            .then(function() {
                                var idx = services.indexOf(service);
                                if (idx > -1) {
                                    services.splice(idx, 1);
                                }
                                $scope.$emit('job.touch', $scope.model.job);
                            })
                            .catch(function(reason) {
                                $scope.$emit('toastMessage', { text: reason, level: 'error' });
                            });
                    } else {
                        // save to server
                        jobsService.saveService($scope.model.job, service)
                            .then(function (js) {
                                if (!orig.id) {
                                    services.push(js);
                                }
                                $scope.$emit('job.touch', $scope.model.job);
                            })
                            .catch(function (reason) {
                                ng.extend(service, orig);
                                $scope.$emit('toastMessage', { text: reason, level: 'error' });
                            });
                    }
                }, function() {
                    ng.extend(service, orig);
                });
            };
        }
    ]);

    module.controller('JobServiceCtrl', [
        '$scope', '$modalInstance', 'servicesService', 'service', 'billedOn',
        function($scope, $modalInstance, servicesService, service, billedOn) {
            $scope.selection = null;

            $scope.service = service;
            $scope.billedOn = billedOn;

            $scope.suggestions = servicesService.suggest;

            $scope.select = function($item, $model, $label) {
                $scope.service.label = $item.label;
            };

            $scope.close = function() {
                $scope.service.quantity = Number($scope.service.quantity);
                $scope.service.price = Number($scope.service.price);
                $modalInstance.close($scope.service);
            };
        }
    ]);

    module.controller('ElevationCtrl', [
        '$scope', 'job', '$stateParams', '$modal', '$timeout', 'lodash', 'addressLookup', 'jobsService', 'Watcher',
        function($scope, job, $stateParams, $modal, $timeout, lodash, addressLookup, jobsService, Watcher) {
            var mirror = function() {
                var self = {
                    navdCdOffset: 20.43,
                    properties: [
                        'curbTop', 'streetCenterLine', 'frontRight', 'frontLeft', 'rearRight', 'rearLeft',
                        'orleansOther', 'constructionBenchmarkElevation', 'refBenchmarkElevation', 'pier',
                        'orleansAsBuilt', 'lowestHorizMember',
                        'topOfBottomFloor', 'topOfNextHigherFloor', 'attachedGarage', 'lowestMachinery',
                        'lowestAdjacentGrade', 'highestAdjacentGrade', 'lagAtDeckOrStairs',
                        'averageGrade', 'adjacentGround', 'otherElevation', 'topOfForm', 'garageForm'
                    ]
                };

                self.navdToCD = function(navd) {
                    return navd + self.navdCdOffset;
                };

                self.cdToNAVD = function(cd) {
                    return cd - self.navdCdOffset;
                };

                self.round = function(value, decimals) {
                    return Number(Math.round(Number(value+'e'+decimals))+'e-'+decimals);
                };

                self.createHandler = function(fromObj, toObj, property, converter) {
                    var prop = property;

                    return function(newVal, oldVal) {
                        if (newVal !== oldVal) {
                            if ((newVal !== 0 && !newVal) || lodash.isNaN(newVal)) {
                                toObj[prop] = null;
                            } else {
                                var rounded = self.round(newVal, '2');
                                if (rounded !== newVal) {
                                    fromObj[prop] = rounded;
                                } else {
                                    var converted = self.round(converter(newVal), '2');
                                    if (converted !== toObj[prop]) {
                                        toObj[prop] = converted;
                                    }
                                }
                            }
                        }
                    };
                };

                self.startMirroring = function(scope) {
                    lodash.forEach(self.properties, function(prop) {
                        self[prop] = (scope.model.elevation[prop] || (scope.model.elevation[prop] == 0))
                            ? self.round(self.navdToCD(scope.model.elevation[prop]), '2') : null;

                        scope.$watch('model.elevation.' + prop, self.createHandler(scope.model.elevation, self, prop, self.navdToCD));
                        scope.$watch('model.mirror.' + prop, self.createHandler(self, scope.model.elevation, prop, self.cdToNAVD));
                    });
                };

                return self;
            };

            $scope.model = {
                jobId: $stateParams.id,
                jobTitle: 'Job ' + $stateParams.id,
                job: job,
                position: $stateParams.elevation,
                elevation: null,
                loadImages: false,
                mirror: mirror(),
                alternatives: jobsService.alternatives(),
                addresses: addressLookup.addresses,
                uploads: {}
            };

            $scope.model.removePhoto = function(side) {
                $scope.model.elevation['photo' + side] = null;
                $scope.model.elevation['photo' + side + 'Lg'] = null;
            };

            $scope.model.fileSelected = function(side, file) {
                if (file) {
                    $scope.model.uploads[side] = file;
                } else {
                    delete $scope.model.uploads[side];
                }
            };

            $scope.model.onLocationSelect = function($item, $model, $label) {
                $scope.model.elevation.addrStreet = $item.street;
                $scope.model.elevation.addrCity = $item.city;
                $scope.model.elevation.addrState = $item.state;
                $scope.model.elevation.addrZip = $item.zip;
                $scope.model.elevation.addrParish = $item.parish;
            };

            $scope.model.lookupLonLatMessage = 'Lookup Lon/Lat'
            $scope.model.lookupLonLat = function() {
                var e = $scope.model.elevation,
                    address = e.addrStreet + ', ' + e.addrCity + ', ' + e.addrState + ' ' + e.addrZip;
                addressLookup.latLon(address).then(function(result) {
                    if (result) {
                        e.edit();
                        ng.extend(e, result);
                        $scope.save();
                    } else {
                        $scope.model.lookupLonLatMessage = 'No matches';
                        $timeout(function() {
                            $scope.model.lookupLonLatMessage = 'Lookup Lon/Lat';
                        }, 5000);
                    }
                });
            };

            $scope.busyWatcher = new Watcher();

            $scope.save = function() {
                $scope.busyWatcher.begin();
                var promise = jobsService.patchElevation($stateParams.id, $stateParams.elevation, $scope.model.elevation);

                promise.then(function(elevation) {
                        var picked = lodash.pick($scope.model.uploads, function(value) { return value; });
                        $scope.$emit('job.touch', $scope.model.job);
                        $scope.model.uploads = {};
                        return lodash.map(picked, function(value, key) {
                            return { elevation: elevation, side: key, file: value, progress: 0, type: 'success' };
                        });
                    }, function(reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    })
                    .then(function(uploads) {
                        if (uploads && uploads.length) {
                            $modal.open({
                                templateUrl: '/templates/jobs/photo-upload-progress.html',
                                controller: 'PhotoUploadProgressCtrl',
                                resolve: {
                                    photos: function () {
                                        return uploads;
                                    }
                                },
                                backdrop: 'static',
                                keyboard: false
                            });
                        }
                    });
            };

            $scope.alternative = function(id) {
                if (id) {
                    return lodash.find($scope.model.alternatives, { 'id': id });
                }
            };


            $scope.$on('updated.job', function(event, args) {
                if (args.data.id === $scope.model.job.id) {
                    var obj = ng.extend({}, args.data);
                    delete obj.id;
                    $scope.model.job.orderForm.update(obj);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.job-billing', function (event, args) {
                if (args.data.id === $scope.model.job.id) {
                    job.billingInfo.billedOn = args.data.billedOn;
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('created.job-comment', function(event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    job.comments.push(new Comment(args.data));
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('created.credit-applications', function(event, args) {
                if (args.data.length && args.data[0].jobId === $scope.model.job.id) {
                    $scope.model.job.addPayments(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.credit-applications', function (event, args) {
                var job = $scope.model.job,
                    credit;
                ng.forEach(args.data, function(patch) {
                    if (job.id === patch.jobId) {
                        credit = lodash.find(job.billingInfo.payments, {id: patch.id});
                        if (credit) {
                            ng.extend(credit, patch);
                            $scope.model.job.touch(args.username);
                        }
                    }
                });
            });

            $scope.$on('deleted.credit-applications', function (event, args) {
                var idx;
                ng.forEach(args.data, function(id) {
                    idx = lodash.findIndex(job.billingInfo.payments, {id: id});
                    if (idx > -1) {
                        job.billingInfo.payments.splice(idx, 1);
                        $scope.model.job.touch(args.username);
                    }
                });
            });

            $scope.$on('created.job-service', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    $scope.model.job.billingInfo.services.push(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.job-service', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    var svc = lodash.find($scope.model.job.billingInfo.services, {id: args.data.id});
                    if (svc) {
                        ng.extend(svc, args.data);
                        $scope.model.job.touch(args.username);
                    }
                }
            });

            $scope.$on('created.elevation', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    $scope.model.job.elevations.push(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            $scope.$on('updated.elevation', function (event, args) {
                if (args.data.jobId === $scope.model.job.id) {
                    var el = lodash.find($scope.model.job.elevations, {id: args.data.id});
                    if (el) {
                        ng.extend(el, args.data);
                        $scope.model.job.touch(args.username);
                    }
                }
                if (args.data.id === $scope.model.elevation.id) {
                    var el = lodash.find($scope.model.job.elevations, {id: args.data.id});
                    if (el) {
                        ng.extend(el, args.data);
                    }
                    $scope.model.elevation.update(args.data);
                    $scope.model.job.touch(args.username);
                }
            });

            (function() {
                $scope.busyWatcher.begin();
                jobsService.getElevation($stateParams.id, $stateParams.elevation)
                    .then(function(elevation) {
                        $scope.model.elevation = elevation;
                        $scope.model.mirror.startMirroring($scope);
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    })
            }());
        }
    ]);

    module.controller('PhotoUploadProgressCtrl', [
        '$scope', '$q', '$upload', 'lodash', 'moment', 'photos',
        function($scope, $q, $upload, lodash, moment, photos) {
            $scope.photos = photos;
            $scope.finishedWithError = false;

            function getError(status, message) {
                if (message) {
                    return message + ' (' + status.toString() + ')';
                }
                var response = 'Upload failure';
                if (status === 500) {
                    response = 'Server error';
                } else if (status === 502 || status == 503 || status === 504 || status === 405) {
                    response =  'The server is not responding';
                } else if (status === 400) {
                    response = 'Invalid or unsupported image file';
                } else if (status === 404) {
                    response = 'Upload URL not found';
                } else if (status === 408) {
                    response = 'The request timed out';
                } else if (status === 413) {
                    response = 'The upload is too large';
                }
                return response + ' (' + status.toString() + ')';
            }

            // create promises for each upload and make closeable when they all complete.
            // if they all complete without error, close immediately
            var promises = [];
            ng.forEach(photos, function(photo) {
                var deferred = $q.defer();
                promises.push(deferred.promise);

                $upload.upload({
                    url: '/photos',
                    method: 'POST',
                    data: {
                        id: photo.elevation.id,
                        created: moment(photo.elevation.createdOn).format('YYYY/MM/DD'),
                        side: photo.side.toLowerCase()
                    },
                    file: photo.file
                }).progress(function(evt) {
                    photo.progress = evt.loaded;
                }).success(function(data) {
                    photo.elevation['photo' + photo.side] = data.photo;
                    photo.elevation['photo' + photo.side + 'Lg'] = data.large;
                    deferred.resolve(true);
                }).error(function(data, status) {
                    photo.type = 'danger';
                    photo.errorMessage = getError(status, data.message);
                    photo.progress = photo.file.size;
                    deferred.resolve(false);
                });
            });

            $q.all(promises).then(function(results) {
                var allOK = lodash.all(results);
                if (allOK) {
                    $scope.$close(allOK);
                } else {
                    $scope.finishedWithError = true;
                }
            });
        }
    ]);

    // printing
    module.controller('ToolbarCtrl', [
        '$scope', '$state', '$q', '$modal', 'lib', 'jobsService', 'surveyorService',
        'invoices', 'paymentMethods', 'credits', 'creditApplications',
        function($scope, $state, $q, $modal, lib, jobsService, surveyorService,
                 invoices, paymentMethods, credits, creditApplications) {
            var _preferredLocationIndex = function() {
                var reply = 0;

                if ($scope.model.elevation && $scope.model.job) {
                    ng.forEach($scope.model.job.elevations, function(el, idx) {
                        if (el.id == $scope.model.elevation.id) {
                            reply = idx;
                        }
                    });
                }

                return reply;
            };

            $scope.model.print = {
                isOpen: false
            };

            $scope.model.eat = function($event) {
                $event.preventDefault();
            };

            $scope.model.acceptPayment = function() {
                var promise = $modal.open({
                    templateUrl: '/templates/accounts/accept-payment-modal.html',
                    backdrop: 'static',
                    controller: 'AcceptPaymentCtrl',
                    size: 'lg',
                    resolve: {
                        job: function() { return $scope.model.job; },
                        allJobs: lib.lodash.partial(invoices.index, $scope.model.job.orderForm.customer.id),
                        paymentMethods: paymentMethods.index
                    }
                }).result;

                promise.then(function(result) {
                    credits.post(result.credit, result.applications)
                        .then(function(applications) {
                            $scope.model.job.addPayments(applications);
                            $scope.$emit('job.touch', $scope.model.job);
                        })
                        .catch(lib.util.emitError($scope));
                });
            };

            $scope.model.createRefund = function(amount, createCredit) {
                var jobId = $scope.model.job.id;
                var promise = $modal.open({
                    templateUrl: '/templates/accounts/create-refund-modal.html',
                    controller: 'CreateRefundCtrl',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        job: function() { return $scope.model.job; },
                        amount: function() { return Math.max(amount, 0); },
                        createCredit: function() { return createCredit !== false; },
                        paymentMethods: paymentMethods.index
                    }
                }).result;

                promise.then(function(result) {
                    var refund,
                        credit = null;

                    refund = {
                        paymentMethodId: result.paymentMethod.id,
                        amount: result.amount * -1,
                        txDate: result.txDate,
                        referenceNumber: result.referenceNumber
                    };

                    if (result.createCredit) {
                        credit = {
                            paymentMethodId: paymentMethods.CREDIT,
                            amount: result.amount,
                            txDate: result.txDate,
                            referenceNumber: result.referenceNumber + ' (REFUND CREDIT)'
                        };
                    }

                    credits.refund(jobId, refund, credit)
                        .then(function(applications) {
                            $scope.model.job.addPayments(applications);
                            $scope.$emit('job.touch', $scope.model.job);
                        })
                        .catch(lib.util.emitError($scope));
                });
            };

            $scope.model.rebalancePayment = function() {
                var job = $scope.model.job;
                var promise = $modal.open({
                    templateUrl: '/templates/accounts/rebalance-payment-modal.html',
                    controller: 'RebalancePaymentCtrl',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        job: function() { return job; },
                        credits: function() { return job.billingInfo.payments; },
                        removeAmount: function() { return job.billingInfo.creditAmount - job.billingInfo.billAmount; }
                    }
                }).result;

                promise.then(function(diffs) {
                    var request = creditApplications.rebalance(diffs);

                    request.then(function(reply) {
                        job.updatePayments(reply);
                        $scope.$emit('job.touch', $scope.model.job);
                    })
                    .catch(lib.util.emitError($scope));

                    request.then(function() {
                        credits.available(job.orderForm.customer.id)
                            .then(function(credits) {
                                job.setAvailableCredits(credits);
                            })
                            .catch(lib.util.emitError($scope))
                    });

                    $scope.$emit('busy.begin', request);
                });
            };

            $scope.model.createFollowUp = function() {
                var saveJob = function(model) {
                    return jobsService.createFollowUp(model.jobId, model.copyServices);
                };
                var goToJob = function(jobId) {
                    $state.go('jobs.details.overview', { id: jobId });
                };
                var modalScope = $scope.$new(true);
                modalScope.model = {
                    jobId: $scope.model.job.id,
                    copyServices: true
                };
                var promise = $modal.open({
                    templateUrl: '/templates/jobs/create-follow-up-modal.html',
                    scope: modalScope
                }).result;
                promise.then(saveJob).then(goToJob).catch(function(reason) {
                    if (reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    }
                });
            };

            $scope.model.invoice = function($event) {
                if ($scope.model.job.billingInfo.billedOn) return;
                $event.preventDefault();

                var modalScope = $scope.$new(true);
                modalScope.jobId = $scope.model.job.id;
                modalScope.markInvoiced = '1';
                $modal.open({
                    templateUrl: '/templates/jobs/mark-invoiced.html',
                    scope: modalScope
                });
            };

            $scope.model.printSetup = function($event, formName, what) {
                $event.preventDefault();

                var modalScope = $scope.$new(true);
                modalScope.job = $scope.model.job;
                modalScope.formName = formName;
                modalScope.what = what;
                modalScope.elevation = $scope.model.job.elevations[_preferredLocationIndex()];
                surveyorService.index()
                    .then(function(surveyors) {
                        modalScope.surveyors = surveyors;
                        modalScope.surveyor = surveyors[0];
                        $modal.open({
                            templateUrl: 'templates/jobs/print-form-dialog.html',
                            scope: modalScope
                        });
                    });
            };

            $scope.model.orleansPrintSetup = function($event, version) {
                $event.preventDefault();
                if ($scope.model.job.orderForm.orleansContinuationOfId) {
                    var modalScope = $scope.$new(true);
                    modalScope.job2 = $scope.model.job;
                    modalScope.elevation2 = modalScope.job2.elevations[_preferredLocationIndex()];

                    $q.all([jobsService.get($scope.model.job.orderForm.orleansContinuationOfId),
                            surveyorService.index()])
                        .then(function(hash) {
                            modalScope.job1 = hash[0];
                            modalScope.elevation1 = modalScope.job1.elevations[0];
                            if ($scope.model.elevation) {
                                var idx = _preferredLocationIndex();
                                if (idx <= modalScope.job1.elevations.length) {
                                    modalScope.elevation1 = modalScope.job1.elevations[idx];
                                }
                            }
                            modalScope.surveyors = hash[1];
                            modalScope.surveyor = modalScope.surveyors[0];
                            modalScope.version = version;
                            $modal.open({
                                templateUrl: 'templates/jobs/print-orleans-dialog.html',
                                scope: modalScope,
                                size: 'lg'
                            });
                        });

                } else {
                    $scope.model.printSetup($event, 'orleans/' + version, 'Orleans (Section 1 Only)');
                }
            };

        }
    ]);

}(angular));
