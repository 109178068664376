(function (ng) {
    "use strict";

    var module = ng.module('app.services.services', [
    ]);

    module.factory('servicesService', [
        '$http', '$q',
        function($http, $q) {
            var instance = {};

            instance.suggest = function(term) {
                var d = $q.defer();

                $http.get('/api/services/suggest', { params: { q: term }})
                    .success(function(response) {
                        d.resolve(response.suggestions);
                    })
                    .error(function(data, status) {
                        d.reject('The server is not responding. (' + status.toString() + ')');
                    });

                return d.promise;
            };

            return instance;
        }
    ]);

}(angular));
