(function(ng) {
    "use strict";
    var module = ng.module('app.auth', ['ngCookies', 'ui.router', 'ui.bootstrap', 'app.lib', 'app.services']);

    module.config(['$stateProvider', '$httpProvider',
        function($stateProvider, $httpProvider) {
            $stateProvider
                .state('auth', {
                    url: '/auth',
                    template: '<ui-view/>',
                    abstract: true
                })
                .state('auth.sign-out', {
                    url: '/sign-out',
                    templateUrl: '/templates/auth/sign-out.html',
                    controller: 'SignOutCtrl'
                });
            $httpProvider.interceptors.push(['$rootScope', '$q', function ($rootScope, $q) {
                return {
                    responseError: function (rejection) {
                        if (rejection.status === 401 && !rejection.config.ignoreAuthModule) {
                            $rootScope.$broadcast('event:auth-loginRequired', rejection);
                        }
                        return $q.reject(rejection);
                    }
                };
            }]);
        }
    ]);

    module.factory('authService', ['$rootScope', function($rootScope) {
        return {
          /**
           * Call this function to indicate that authentication was successful and trigger a
           * retry of all deferred requests.
           * @param data an optional argument to pass on to $broadcast which may be useful for
           * example if you need to pass through details of the user that was logged in
           */
          loginConfirmed: function(data) {
            $rootScope.$broadcast('event:auth-loginConfirmed', data);
          },

          /**
           * Call this function to indicate that authentication should not proceed.
           * @param data an optional argument to pass on to $broadcast.
           */
          loginCancelled: function(data) {
            $rootScope.$broadcast('event:auth-loginCancelled', data);
          }
        };
    }]);

    module.directive('requireAuth', [
        '$modal', 'authService',
        function($modal, authService) {
            return function(scope, element) {
                scope.$on('event:auth-loginRequired', function() {
                    if (!scope.signingIn) {
                        scope.signingIn = true;
                        var dlg = $modal.open({
                            templateUrl: '/templates/auth/sign-in.html',
                            controller: 'SigninCtrl',
                            backdrop: 'static'
                        });
                        dlg.result.then(
                            function() { scope.signingIn = false; },
                            function() { scope.signingIn = false; authService.loginCancelled(); }
                        );
                    }
                });
            };
        }
    ]);

    module.controller('SigninCtrl', [
        '$scope', '$cookies', '$location', '$timeout', '$modalInstance',
        function($scope, $cookies, $location, $timeout, $modalInstance) {
            var _model = $scope.model = {
                username: null,
                password: null,
                path: $location.absUrl(),
                messages: []
            };

            if (_model.path.indexOf('/auth/sign-out') > -1) {
                _model.path = '';
            }

            if ($cookies['_gkc_messages']) {
                var messages = ng.fromJson($cookies['_gkc_messages']);
                ng.forEach(messages, function(val) { _model.messages.push(val); });
                delete $cookies['_gkc_messages'];
            }

            $scope.model.removeMessage = function(idx) {
                $scope.model.messages.splice(idx, 1);
            };
        }
    ]);

    module.controller('SignOutCtrl', [
        '$scope', 'userService',
        function($scope, userService) {
            $scope.user = userService;
            userService.signOut();
        }
    ]);

    module.factory('userService', [
        '$rootScope', '$http', '$cookies', 'lodash', 'mediator',
        function($rootScope, $http, $cookies, lodash, mediator) {
            var instance = {
                    username: '',
                    displayName: '',
                    isSignedIn: false,
                    roles: [],
                    connected: false
                },
                _local = {};

            instance.isInRole = function(roleOrArray) {
                var reply = false;
                if (!roleOrArray) {
                    return reply;
                }
                if (lodash.isArray(roleOrArray)) {
                    reply = lodash.any(roleOrArray, function(r) {
                        lodash.contains(instance.roles, r);
                    });
                } else {
                    reply = lodash.contains(instance.roles, roleOrArray);
                }
                return reply;
            };

            _local.load = function(data) {
                instance.isSignedIn = true;
                instance.username = data.username;
                instance.displayName = data.displayName;
                instance.id = data.id;
                instance.roles.length = 0;
                lodash.forEach(data.roles, function(r) { instance.roles.push(r); });
                mediator.connect();
                $rootScope.$broadcast('event:user-signed-in', instance);
            };

            _local.unload = function() {
                instance.isSignedIn = false;
                instance.username = '';
                instance.displayName = '';
                instance.id = 0;
                instance.roles.length = 0;
                delete $cookies['_trd_auth'];
                mediator.disconnect();
                $rootScope.$broadcast('event:user-signed-out', instance);
            };

            instance.load = function(force) {
                if (force || !instance.isSignedIn) {
                    // load from server
                    $http.get('/api/auth/currentuser')
                        .success(function(data) {
                            _local.load(data);
                        });
                }
            };

            instance.signOut = function() {
                $http.get('/api/auth/signout')
                    .finally(function() {
                        _local.unload();
                        $rootScope.$broadcast('event:auth-loginRequired');
                    });
            };

            $rootScope.$on('event:auth-loginRequired', function() {
                _local.unload();
            });
            $rootScope.$on('event:auth-loginConfirmed', _local.load);

            $rootScope.$on('mediator.connected', function(data) {
                instance.connected = data.connected;
            });
            $rootScope.$on('mediator.disconnected', function(data) {
                instance.connected = data.connected;
            });

            return instance;
        }
    ]);

}(angular));
