(function(ng) {
    "use strict";
    var module = ng.module('app.surveyors.services', [
        'app.navigation', 'app.services', 'app.lib'
    ]);

    module.factory('Surveyor', [
        'lodash', 'inherit', 'Entity', 'Memento',
        function(lodash, inherit, Entity, Memento) {
            function Surveyor(json) {
                Entity.call(this, json);
            }
            inherit(Surveyor, [Entity, Memento]);
            return Surveyor;
        }
    ]);

    module.factory('surveyorService', [
        '$http', '$cacheFactory', '$rootScope', '$q', 'Pager', 'Surveyor',
        function($http, $cacheFactory, $rootScope, $q, Pager, Surveyor) {
            var instance = {
                hits: [],
                cache: $cacheFactory('surveyorService')
            };

            instance.index = function() {
                var deferred = $q.defer();

                $http.get('/api/surveyors', { cache: instance.cache })
                    .success(function(response) {
                        instance.hits.length = 0;
                        instance.hits.push.apply(instance.hits, response.hits);
                        deferred.resolve(instance.hits);
                    })
                    .error(function(data, status) {
                        deferred.reject('The server is not responding. (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.get = function(id) {
                var deferred = $q.defer();

                $http.get('/api/surveyors/' + id.toString(), { cache: instance.cache })
                    .success(function(response) {
                        deferred.resolve(new Surveyor(response.surveyor));
                    })
                    .error(function(data, status, headers, config) {
                        deferred.reject('The server is not responding. (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.patch = function(surveyor) {
                var deferred = $q.defer();
                var patch = surveyor.save();
                if (patch.diff) {
                    $http({
                        method: 'PATCH',
                        url: '/api/surveyors/' + surveyor.id.toString(),
                        data: patch.diff
                    }).success(function() {
                        instance.cache.removeAll();
                        deferred.resolve(surveyor);
                    }).error(function(data, status, headers, config) {
                        surveyor.rollback(patch.orig);
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });
                } else {
                    deferred.resolve(surveyor);
                }

                return deferred.promise;
            };

            instance.post = function(surveyor) {
                var deferred = $q.defer();
                var patch = surveyor.save();
                delete patch.diff.id;
                $http.post('/api/surveyors', patch.diff)
                    .success(function(data) {
                        instance.cache.removeAll();
                        surveyor.replace(data.surveyor);
                        deferred.resolve(surveyor);
                    })
                    .error(function(data, status, headers, config) {
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.create = function() {
                return new Surveyor();
            };

            var uncache = function(evt, args) {
                instance.cache.remove('/api/surveyors');
                instance.cache.remove('/api/surveyors/' + args.data.id);
            };

            $rootScope.$on('mediator.connected', instance.cache.removeAll);
            $rootScope.$on('mediator.disconnected', instance.cache.removeAll);
            $rootScope.$on('updated.surveyor', uncache);
            $rootScope.$on('created.surveyor', uncache);


            return instance;
        }
    ]);

}(angular));
