(function(ng) {
    "use strict";
    var module = ng.module('app.filters', ['app.lib']);

    module.filter('dateFormat', function() {
        return function(input, formatString) {
            if (!input) return input;
            formatString = formatString || 'l';
            if (!moment.isMoment(input)) {
                input = moment(input);
            }
            return input.format(formatString);
        };
    });

    module.filter('gmailDate', ['moment',
        function(moment) {
            return function(input, converttz) {
                var dt = input,
                    day,
                    year;
                if (!input) {
                    return input;
                }
                if (!moment.isMoment(dt)) {
                    if (converttz) {
                        dt = moment.utc(dt).local();
                    } else {
                        dt = moment(dt);
                    }
                }
                if (!dt.isValid()) {
                    return input;
                }
                year = moment().startOf('year');

                if (dt.year() === year.year() || dt.isAfter(year)) {
                    return dt.format('MMM D');
                }
                return dt.format('l');
            };
        }
    ]);

    module.filter('gmailDateTime', ['moment',
        function(moment) {
            return function(input, converttz) {
                var dt = input,
                    day,
                    year;
                if (!input) {
                    return input;
                }
                if (!moment.isMoment(dt)) {
                    if (converttz) {
                        dt = moment.utc(dt).local();
                    } else {
                        dt = moment(dt);
                    }
                }
                if (!dt.isValid()) {
                    return input;
                }
                day = moment().startOf('day');
                year = moment().startOf('year');

                if (dt.isAfter(year)) {
                if (dt.dayOfYear() === day.dayOfYear()) {
                    return dt.format('LT');
                }
                    return dt.format('MMM D');
                }
                return dt.format('l');
            };
        }
    ]);

    module.filter('phoneFormat', function() {
        return function(input) {
            if (!input) return '';
            var parts = input.split('x'),
                num = parts[0],
                ext = parts.length == 2 ? parts[1] : '',
                formatted = num;

            if (num.length == 10) {
                formatted = num.substr(0, 3) + '-' + num.substr(3, 3) + '-' + num.substr(6, 4);
            } else if (num.length == 7) {
                formatted = num.substr(0, 3) + '-' + num.substr(3, 4);
            }
            if (ext) {
                formatted += ' x';
                formatted += ext;
            }
            return formatted;
        };
    });

    module.filter('lines', function() {
        return function(input) {
            return (input || '').toString().split('\n');
        };
    });

    module.filter('yesno', ['lodash',
        function(lodash) {
            return function(input, emptyText, undefinedAsFalse) {
                if (!input) {
                    if (!undefinedAsFalse) {
                        return lodash.isUndefined(input) || lodash.isNull(input) ? emptyText : 'No';
                    }
                    return 'No';
                }
                return 'Yes';
            };
        }
    ]);

    module.filter('percent', function() {
        return function(input) {
            if (ng.isNumber(input)) {
                return Math.round(input * 100);
            }
            return input;
        }
    });

    module.filter('dashed', function() {
        return function(input) {
            if (input) {
                return String(input).match(/\S+/g).join('-');
            }
            return input;
        };
    });

    module.filter('title', function() {
        return function(input) {
            if (input) {
                return input.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
            }
            return input;
        }
    });

    module.filter('surround', function() {
        return function(input, prefix, suffix) {
            if (input) {
                return (prefix || '') + input + (suffix || '');
            }
            return input;
        }
    });

}(angular));
