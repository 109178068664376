"use strict";
(function(ng) {

    var app = ng.module('app',
        [
            'ui.router', 'ui.bootstrap', 'ui.router', 'app.lib',
            'app.auth', 'app.controllers', 'app.directives', 'app.filters', 'app.navigation', 'app.services',
            'app.accounts.controllers',
            'app.auth.controllers',
            'app.customers.controllers',
            'app.jobs.controllers',
            'app.services.services',
            'app.surveyors.controllers'
        ]);

    app.config([
        '$urlRouterProvider', '$uiViewScrollProvider', '$httpProvider', 'clientId',
        function($urlRouterProvider, $uiViewScrollProvider, $httpProvider, clientId) {
            // this turns off weird, annoying 'scroll to view' behavior
            $uiViewScrollProvider.useAnchorScroll();
            // default view
            $urlRouterProvider.otherwise('/jobs/active/1');
            $httpProvider.defaults.headers.common['X-Client-UUID'] = clientId;
            //disable IE ajax request caching
            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = {};
            }
            $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
        }
    ]);

    app.run([
        '$rootScope', '$window', '$templateCache', 'backService',
        function($rootScope, $window, $templateCache, backService) {
            $rootScope.todo = function(msg) {
                $window.alert(msg || 'To do');
            };

            // backService is just included to make sure the state change listeners get hooked up

            $templateCache.put("template/timepicker/timepicker.html",
              "<table>\n" +
              "	<tbody>\n" +
              "		<tr>\n" +
              "			<td style=\"width:50px;\" class=\"form-group\" ng-class=\"{'has-error': invalidHours}\">\n" +
              "				<input type=\"text\" ng-model=\"hours\" ng-change=\"updateHours()\" class=\"form-control text-center\" ng-mousewheel=\"incrementHours()\" ng-readonly=\"readonlyInput\" maxlength=\"2\">\n" +
              "			</td>\n" +
              "			<td>:</td>\n" +
              "			<td style=\"width:50px;\" class=\"form-group\" ng-class=\"{'has-error': invalidMinutes}\">\n" +
              "				<input type=\"text\" ng-model=\"minutes\" ng-change=\"updateMinutes()\" class=\"form-control text-center\" ng-readonly=\"readonlyInput\" maxlength=\"2\">\n" +
              "			</td>\n" +
              "			<td ng-show=\"showMeridian\"><button type=\"button\" class=\"btn btn-default text-center\" ng-click=\"toggleMeridian()\">{{meridian}}</button></td>\n" +
              "		</tr>\n" +
              "	</tbody>\n" +
              "</table>\n" +
              "");

            $templateCache.put("template/datepicker/day.html",
              "<table role=\"grid\">\n" +
              "  <thead>\n" +
              "    <tr>\n" +
              "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-left\"></i></button></th>\n" +
              "      <th colspan=\"{{5 + showWeeks}}\"><button id=\"{{uniqueId}}-title\" role=\"heading\" type=\"button\" class=\"btn btn-default btn-sm\" ng-click=\"toggleMode()\" tabindex=\"-1\" style=\"width:100%;\"><strong>{{title}}</strong></button></th>\n" +
              "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-right\"></i></button></th>\n" +
              "    </tr>\n" +
              "    <tr>\n" +
              "      <th ng-show=\"showWeeks\" class=\"text-center\"></th>\n" +
              "      <th ng-repeat=\"label in labels track by $index\" class=\"text-center\"><small>{{label.abbr}}</small></th>\n" +
              "    </tr>\n" +
              "  </thead>\n" +
              "  <tbody>\n" +
              "    <tr ng-repeat=\"row in rows track by $index\">\n" +
              "      <td ng-show=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td>\n" +
              "      <td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{dt.uid}}\">\n" +
              "        <button type=\"button\" style=\"width:100%;\" class=\"btn btn-default btn-sm\" ng-class=\"{'btn-info': dt.selected}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span class=\"text-info\">{{dt.label}}</span></button>\n" +
              "      </td>\n" +
              "    </tr>\n" +
              "  </tbody>\n" +
              "</table>\n" +
              "");
        }
    ]);

})(angular);
