(function(ng) {
    "use strict";
    var module = ng.module('app.jobs.directives', ['angularFileUpload']);

    module.directive('commentType', function() {
        var map = {
            'Comment': 'fa-comment-o',
            'Cancellation': 'fa-exclamation-triangle text-warning',
            'Un-Cancellation': 'fa-info-circle text-success',

            'Hold': 'fa-info-circle text-warning',
            'Hold Removed': 'fa-info-circle text-success',

            'Flagged': 'fa-exclamation-triangle text-danger',
            'Flag Removed': 'fa-info-circle text-success',

            'Archived': 'fa-info-circle',
            'Unarchived': 'fa-info-circle',

            'Completed': 'fa-info-circle',
            'Completion Reversed': 'fa-info-circle',

            'Created': 'fa-info-circle',
            'Assigned': 'fa-info-circle',
            'Invoiced': 'fa-info-circle',
            'Payment': 'fa-money'
        };
        return {
            restrict: 'A',
            scope: {
                'commentType': '='
            },
            link: function(scope, element) {
                if (map[scope.commentType]) {
                    element.addClass(map[scope.commentType]);
                }
            }
        }
    });

    module.directive('cdNavd', function() {
        return {
            restrict: 'E',
            replace: true,
            require: '^form',
            templateUrl: '/templates/jobs/cd-navd.html',
            scope: {
                label: '@',
                prop: '@',
                cd: '=',
                navd: '='
            },
            link: function(scope, element, attrs, formCtrl) {
                // Why oh god why?
                // See https://github.com/angular/angular.js/pull/3135
                scope._photo = formCtrl['_NAVD_'];
                scope._cd = formCtrl['_CD_'];

                formCtrl.$removeControl(scope._photo);
                formCtrl.$removeControl(scope._cd);

                scope._photo.$name = scope.prop + 'NAVD';
                scope._cd.$name = scope.prop + 'CD';

                formCtrl.$addControl(scope._photo);
                formCtrl.$addControl(scope._cd);

                scope.navdInvalid = scope.cdInvalid = false;

                scope.$watch('_navd.$dirty && _photo.$error.number', function(value) {
                    scope.navdInvalid = value;
                });
                scope.$watch('_cd.$dirty && _cd.$error.number', function(value) {
                    scope.cdInvalid = value;
                });
            }
        };
    });

    module.directive('photo', function() {
        return {
            restrict: 'E',
            replace: true,
            require: '^form',
            templateUrl: '/templates/jobs/photo.html',
            scope: {
                url: '=',
                file: '=',
                side: '@',
                isEditing: '@',
                remove: '&',
                fileSelected: '&'
            },
            link: function(scope, element, atts, formCtrl) {
                scope.$watch('url', function(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        formCtrl.$setDirty();
                    }
                });

                scope.onFileSelect = function($files) {
                    if ($files[0]) {
                        formCtrl.$setDirty();
                    }
                    scope.fileSelected({side: scope.side, file: $files[0]});
                };
            }
        };
    });

    module.directive('filters', function() {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                'onFilter': '&',
                'file': '=',
                'cust': '=',
                'street': '=',
                'district': '=',
                'subdivision': '=',
                'lot': '=',
                'square': '='
            },
            templateUrl: '/templates/jobs/filter.html'
        };

    });
}(angular));
