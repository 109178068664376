"use strict";
(function(ng, _, moment) {

    var module = ng.module('app.lib', []);

    var _util = {};

    _util.httpError = function(deferred) {
        var _deferred = deferred;
        return function(data, status) {
            if (data.userMessage) {
                _deferred.reject(data.userMessage + ' (' + status.toString() + ')');
            } else {
                _deferred.reject('There was an error saving your changes (' + status.toString() + ')');
            }
        };
    };

    _util.emitError = function(scope) {
        var _scope = scope;
        return function(reason) {
            _scope.$emit('toastMessage',
                {
                    text: reason,
                    level: 'error'
                });
        };
    };


    module.value('lodash', _);
    module.value('moment', moment);

    module.value('lib', {
        _: _,
        lodash: _,
        moment: moment,
        util: _util
    });

})(angular, _, moment);
