(function (ng) {
    "use strict";
    var module = ng.module('app.accounts.controllers', [
        'ui.router', 'ui.bootstrap', 'app.directives', 'app.navigation',
        'app.accounts.models', 'app.accounts.services', 'app.customers.services', 'app.lib'
    ]);

    module.config([
        '$stateProvider', '$urlRouterProvider',
        function($stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/accounts', '/accounts/statements/af/1');
            $urlRouterProvider.when('/accounts/deposits', '/accounts/deposits/1');
            $urlRouterProvider.when('/accounts/statements', '/accounts/statements/af/1');
            $stateProvider
                .state('accounts', {
                    url: '/accounts',
                    template: '<ui-view/>',
                    controller: 'AccountsCtrl'
                })
                .state('accounts.deposits', {
                    url: '/deposits',
                    template: '<ui-view/>',
                    controller: 'DepositsCtrl'
                })
                .state('accounts.deposits.deposit', {
                    url: '/deposit/{depositId:[0-9]+}',
                    templateUrl: 'templates/accounts/deposit.html',
                    controller: 'DepositCtrl',
                    resolve: {
                        deposits: 'deposits',
                        deposit: ['deposits', '$stateParams', function(deposits, $stateParams) {
                            return deposits.get($stateParams.depositId);
                        }]
                    }
                })
                .state('accounts.deposits.page', {
                    url: '/{page:[0-9]+}',
                    templateUrl: 'templates/accounts/deposits-page.html',
                    controller: 'DepositsPageCtrl'
                })
                .state('accounts.statements', {
                    url: '/statements',
                    template: '<ui-view/>',
                    controller: 'StatementsCtrl'
                })
                .state('accounts.statements.statement', {
                    url: '/{customerId:[0-9]+}',
                    templateUrl: 'templates/accounts/statement.html',
                    controller: 'StatementCtrl',
                    resolve: {
                        statements: 'statements',
                        statement: ['statements', '$stateParams', function(statements, $stateParams) {
                            return statements.get($stateParams.customerId);
                        }]
                    }
                })
                .state('accounts.statements.search', {
                    url: '/{subset:all|af|gm|ns|tz}/{page:[0-9]+}?term',
                    templateUrl: 'templates/accounts/statements-page.html',
                    controller: 'StatementsPageCtrl'
                })
                .state('accounts.statements.page', {
                    url: '/{subset:all|af|gm|ns|tz}/{page:[0-9]+}',
                    templateUrl: 'templates/accounts/statements-page.html',
                    controller: 'StatementsPageCtrl'
                })
                .state('accounts.aging', {
                    url: '/aging?start&through&inactive',
                    templateUrl: 'templates/accounts/aging.html',
                    controller: 'AgingReportCtrl'
                })
            ;
        }
    ]);

    module.controller('AccountsCtrl', [
        '$scope', 'Watcher',
        function($scope, Watcher) {
            Watcher.installOn($scope);
        }
    ]);

    module.controller('DepositsCtrl', [
        '$scope', '$state', '$modal', 'credits', 'deposits',
        function($scope, $state, $modal, credits, deposits) {
            $scope.model = {
                page: deposits.page,
                undeposited: []
            };

            $scope.commands = {};
            $scope.commands.page = function(pageNumber) {
                $state.go($state.current.name, {page: pageNumber});
            };

            $scope.commands.fetchUndeposited = function() {
                credits.undeposited()
                    .then(function(credits) {
                        $scope.model.undeposited = credits;
                    })
                    .catch(function(reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    });
            };

            $scope.commands.create = function() {
                var promise = $modal.open({
                    backdrop: 'static',
                    templateUrl: '/templates/accounts/create-deposit-modal.html',
                    controller: 'CreateDepositCtrl',
                    resolve: {
                        undeposited: function() { return $scope.model.undeposited; }
                    }
                }).result;

                promise.then(function(result) {
                    deposits.post(result.txDate, result.checked)
                        .then(function(id) {
                            $scope.commands.fetchUndeposited();
                            $state.go('accounts.deposits.deposit', {depositId: id});
                        })
                        .catch(function(reason) {
                            $scope.$emit('toastMessage', { text: reason, level: 'error' });
                        });
                });
            };

            $scope.$on('created.credit', $scope.commands.fetchUndeposited);
            $scope.$on('created.deposit', function() {
                $scope.commands.fetchUndeposited();
            });

            (function() {
                $scope.commands.fetchUndeposited();
            }());
        }
     ]);

    module.controller('DepositsPageCtrl', [
        '$scope', '$stateParams', 'deposits',
        function($scope, $stateParams, deposits) {
            (function() {
                deposits.index($stateParams.page);
            }());
        }
     ]);

    module.controller('DepositCtrl', [
        '$scope', 'deposits', 'deposit',
        function($scope, deposits, deposit) {
            $scope.model = {
                deposit: deposit
            };
        }
     ]);

    module.controller('CreateDepositCtrl', [
        '$scope', 'lib', 'undeposited',
        function($scope, lib, undeposited) {
            var initAvailable = function(txTypes) {
                txTypes = lib.lodash(lib.lodash.map(txTypes.split(','), Number));
                var filtered = lib.lodash.filter(undeposited, function(tx) {
                    return txTypes.contains(tx.paymentMethodId);
                });
                return ng.forEach(ng.copy(filtered), function(tx) {
                    tx.checked = true;
                });
            };
            $scope.model = {
                txDate: lib.moment({hour: 0}),
                availableCredits: initAvailable('1,2'),
                txTypes: '1,2',
                hasChecked: false
            };
            $scope.model.hasChecked = $scope.model.availableCredits.length > 0;

            $scope.commands = {};
            $scope.commands.apply = function(credit) {
                $scope.model.hasChecked = credit.checked || lib.lodash.some($scope.model.availableCredits, 'checked');
            };

            $scope.commands.getChecked = function() {
                var reply = [];
                var checked = lib.lodash.filter($scope.model.availableCredits, 'checked');

                ng.forEach(checked, function(c) {
                    reply.push(c.id);
                });

                return reply;
            };

            $scope.$watch('model.txTypes', function(n, o) {
                if (n !== o) {
                    $scope.model.availableCredits = initAvailable($scope.model.txTypes);
                }
            });
        }
    ]);

    module.controller('StatementsCtrl', [
        '$scope', '$state', 'statements',
        function($scope, $state, statements) {
            $scope.model = {
                page: statements.page
            };


            $scope.searchParams = {
                term: ''
            };

            $scope.commands = {};
            $scope.commands.page = function(pageNumber) {
                $state.go($state.current.name, {page: pageNumber});
            };

            $scope.commands.doSearch = function(term) {
                term = term ? term.replace(/^\s+|\s+$/g, '') : '';
                if (term) {
                    $state.go('accounts.statements.search', {page: 1, term: term});
                } else {
                    $state.go('accounts.statements.page', {page: 1});
                }
            };


            $scope.commands.toggleActive = function(customerId) {
                $scope.todo(customerId);
            };
        }
     ]);

    module.controller('StatementsPageCtrl', [
        '$scope', '$stateParams', 'statements', 'customerService', 'Customer',
        function($scope, $stateParams, statements, customerService, Customer) {
            $scope.nav = [
                {sw:'all', label:'All'},
                {sw:'af', label:'A-F'},
                {sw:'gm', label:'G-M'},
                {sw:'ns', label:'N-S'},
                {sw:'tz', label:'T-Z'}
            ];

            $scope.nav.classFor = function(subset) {
                return subset === $stateParams.subset ? 'btn-primary' : 'btn-default';
            };

            $scope.nav.currentSubset = $stateParams.subset;

            $scope.commands.toggleActive = function(item) {
                var customer = new Customer({id: item.customerId, active: item.active}).edit();
                customer.active = !customer.active;
                item.active = customer.active;
                customerService
                    .patch(customer)
                    .catch(function(reason) {
                        item.active = !item.active;
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    });
            };

            (function() {
                $scope.searchParams.term = $stateParams.term;
                $scope.$emit('busy.begin', statements.index($stateParams.page, $stateParams.subset, $stateParams.term));
            }());
        }
     ]);

    module.controller('StatementCtrl', [
        '$scope', 'statements', 'statement',
        function($scope, statements, statement) {
            $scope.model = {
                statement: statement
            };
            $scope.$emit('busy.end');
        }
     ]);

    // modals
    module.controller('ApplyCreditCtrl', [
        '$scope', 'accountsModels', 'job', 'availableCredits',
        function($scope, accountsModels, job, availableCredits) {
            $scope.model = {
                jobId: job.id,
                balance: job.billingInfo.balance,
                applied: 0,
                availableCredits: []
            };

            ng.forEach(availableCredits, function(c) {
                $scope.model.availableCredits.push(new accountsModels.AppliableCredit(c));
            });

            $scope.model.applications = function() {
                var reply = [];

                ng.forEach($scope.model.availableCredits, function(credit) {
                    ng.forEach(credit.applications, function(application) {
                        reply.push(application);
                    });
                });

                return reply;
            };

            $scope.commands = {};
            $scope.commands.apply = function(credit) {
                if (credit.applications.length) {
                    $scope.model.applied -= credit.clearApplications();
                } else {
                    $scope.model.applied += credit.applyTo(
                        $scope.model.jobId, ($scope.model.balance - $scope.model.applied));
                }
            };
        }
    ]);

    module.controller('AcceptPaymentCtrl', [
        '$scope', 'lib', 'accountsModels', 'job', 'allJobs', 'paymentMethods',
        function($scope, lib, accountsModels, job, allJobs, paymentMethods) {
            $scope.model = {
                jobId: job.id,
                customer: job.orderForm.customer.label,
                invoices: [],
                paymentMethods: paymentMethods,

                paymentMethod: null,
                txAmount: 0,
                txDate: lib.moment(),
                txReference: null,

                remaining: 0
            };

            ng.forEach(allJobs.items, function(item) {
                var invoice = new accountsModels.PayableInvoice(item);
                $scope.model.invoices.push(invoice);
                if (invoice.id === job.id) {
                    $scope.model.txAmount = invoice.balance;
                    $scope.model.remaining = invoice.balance - invoice.apply(invoice.balance);
                    invoice.checked = true;
                }
            });

            $scope.$watch('model.txAmount', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    ng.forEach($scope.model.invoices, function (invoice) {
                        invoice.unapply();
                    });
                    $scope.model.remaining = newVal || 0;
                }
            });

            $scope.commands = {};
            $scope.commands.apply = function(invoice) {
                if (invoice.applied) {
                    $scope.model.remaining += invoice.unapply();
                } else {
                    $scope.model.remaining -= invoice.apply($scope.model.remaining);
                }
            };

            $scope.model.applications = function() {
                var apps = [];

                ng.forEach($scope.model.invoices, function(invoice) {
                    if (invoice.applied) {
                        apps.push({ apply: invoice.applied, jobId: invoice.id });
                    }
                });

                return {
                    credit: {
                        txDate: $scope.model.txDate,
                        referenceNumber: $scope.model.txReference,
                        amount: $scope.model.txAmount,
                        paymentMethodId: $scope.model.paymentMethod.id,
                        customerId: job.orderForm.customer.id
                    },
                    applications: apps
                };
            };
        }
    ]);

    module.controller('RebalancePaymentCtrl', [
        '$scope', 'lib', 'accountsModels', 'job', 'credits', 'removeAmount',
        function($scope, lib, accountsModels, job, credits, removeAmount) {
            $scope.model = {
                amount: removeAmount,
                remaining: removeAmount,
                payments: []
            };

            $scope.commands = {
                apply: function(credit) {
                    if (credit.applied) {
                        $scope.model.remaining += credit.unapply();
                    } else {
                        $scope.model.remaining -= credit.apply($scope.model.remaining);
                    }
                }
            };

            $scope.model.applications = function() {
                return lib.lodash
                    .filter($scope.model.payments, {checked: true})
                    .map(function(payment) {
                        return { id: payment.id, amount: (payment.amount - payment.applied) };
                    });
            };

            (function () {
                var credit;
                ng.forEach(credits, function (payment) {
                    if (payment.amount > 0) {
                        credit = new accountsModels.AppliedCredit(payment);
                        $scope.model.payments.push(credit);

                        // exact match
                        if (credit.amount === $scope.model.remaining) {
                            $scope.commands.apply(credit);
                        }
                    }
                });

                if ($scope.model.payments.length === 1 && $scope.model.remaining) {
                    $scope.commands.apply($scope.model.payments[0]);
                }

            }());

        }
     ]);

    module.controller('CreateRefundCtrl', [
        '$scope', 'lib', 'accountsModels', 'paymentMethods', 'job', 'amount', 'createCredit',
        function($scope, lib, accountsModels, paymentMethods, job, amount, createCredit) {
            $scope.model = {
                jobId: job.id,
                paid: job.billingInfo.paidAmount,
                credited: job.billingInfo.creditAmount - job.billingInfo.paidAmount,
                paymentMethods: paymentMethods,
                customer: job.orderForm.customer.label
            };

            $scope.refund = {
                paymentMethod: null,
                amount: amount,
                txDate: lib.moment(),
                referenceNumber: null,
                createCredit: createCredit
            };

        }
    ]);

    module.controller('AgingReportCtrl', [
        '$scope', '$state', '$stateParams', 'moment', 'aging',
        function($scope, $state, $stateParams, moment, aging) {

            $scope.currentParams = {
                start: $stateParams.start && moment($stateParams.start).isValid() ? moment($stateParams.start) : moment('2008-01-01', 'YYYY-MM-DD'),
                through: $stateParams.through && moment($stateParams.through).isValid() ? moment($stateParams.through) : moment().add(1, 'd').startOf('day'),
                inactive: $stateParams.inactive === '1' ? '1' : '0'
            };

            $scope.filters = ng.copy($scope.currentParams);
            $scope.report = aging.report;

            $scope.commands = {};

            $scope.commands.doSearch = function(start, through, inactive) {
                $state.go('accounts.aging', {
                    start: start.format('YYYY-MM-DD'),
                    through: through.format('YYYY-MM-DD'),
                    inactive: inactive});
            };

            (function() {
                if (!$stateParams.inactive) {
                    $scope.commands.doSearch(
                        $scope.currentParams.start, $scope.currentParams.through, $scope.currentParams.inactive);
                }
                $scope.$emit('busy.begin',
                    aging.index(
                        $scope.currentParams.start, $scope.currentParams.through, $scope.currentParams.inactive));
            }());
        }
     ]);


}(angular));
