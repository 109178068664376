(function(ng) {
    "use strict";
    var module = ng.module('app.jobs.services', [
        'app.navigation', 'app.lib', 'app.accounts.models'
    ]);


    module.factory('Job', [
        'lib', 'inherit', 'JsonObject', 'Comment', 'JobBillingInfo', 'JobOrderForm', 'accountsModels',
        function(lib, inherit, JsonObject, Comment, JobBillingInfo, JobOrderForm, accountsModels) {

            function Job(json) {
                JsonObject.call(this, json);

                this.orderForm = new JobOrderForm(this.orderForm);
                this.billingInfo = new JobBillingInfo(this.billingInfo);

                this.availableCredits = lib.lodash.map(json.availableCredits || [], function(c) {
                    return new accountsModels.AvailableCredit(c);
                });
                this.comments = lib.lodash.map(json.comments, function(c) { return new Comment(c); });
            }
            inherit(Job, [JsonObject]);

            Job.prototype._recalculate = function() {
                var billAmount = 0,
                    creditAmount = 0,
                    paidAmount = 0;

                ng.forEach(this.billingInfo.services, function(service) {
                    billAmount += (service.fee * service.quantity);
                });
                ng.forEach(this.billingInfo.payments, function(payment) {
                    creditAmount += payment.amount;
                    if (payment.paymentMethodLabel !== 'Credit') {
                        paidAmount += payment.amount;
                    }
                });

                this.billingInfo.billAmount = billAmount;
                this.billingInfo.creditAmount = creditAmount;
                this.billingInfo.paidAmount = paidAmount;
                this.billingInfo.balance = billAmount - creditAmount;

                this.billingInfo.showTxHistory = (this.billingInfo.payments.length || this.billingInfo.billedOn) ? true : false;

                this.billingInfo.status = this.billingInfo.balance > 0 ? 'unpaid' : 'paid';
                if (this.billingInfo.status === 'paid' && billAmount === 0) {
                    this.billingInfo.status = 'no charge';
                }
                if (this.billingInfo.status === 'unpaid' && !this.billingInfo.billedOn) {
                    this.billingInfo.status = 'uninvoiced';
                }
            };

            Job.prototype.addPayments = function(applications) {
                var recalc = false,
                    self = this,
                    availableCredit = null;
                ng.forEach(applications, function(payment) {
                    if (payment.jobId === self.id
                        && !lib.lodash.find(self.billingInfo.payments, {'id': payment.id})) {
                        self.billingInfo.payments.push(payment);

                        availableCredit = lib.lodash.find(self.availableCredits, {'id': payment.creditId});
                        if (availableCredit) {
                            availableCredit.available -= payment.amount;
                        }
                        recalc = true;
                    }
                });
                if (recalc) {
                    lib.lodash.remove(self.availableCredits, {'available': 0});
                    this._recalculate();
                }
            };

            Job.prototype.updatePayments = function(changes) {
                var recalc = false,
                    self = this;

                ng.forEach(changes.deletions || [], function(id) {
                    var deleted = lib.lodash.remove(self.billingInfo.payments, {'id': id});
                    if (deleted.length) {
                        recalc = true;
                    }
                });

                ng.forEach(changes.diffs, function(payment) {
                    var entity = lib.lodash.find(self.billingInfo.payments, {'id': payment.id});
                    if (entity) {
                        ng.extend(entity, payment);
                        recalc = true;
                    }
                });

                if (recalc) {
                    this._recalculate();
                }
            };

            Job.prototype.setAvailableCredits = function(availableCredits) {
                var self = this;
                self.availableCredits.length = 0;

                ng.forEach(availableCredits, function(c) {
                    self.availableCredits.push(new accountsModels.AvailableCredit(c));
                });
            };

            Job.prototype.touch = function(username) {
                ng.extend(this.orderForm.updated, { username: username, changeDate: lib.moment() });
            };

            return (Job)
        }
    ]);

    module.factory('Comment', [
        'lib', 'inherit', 'JsonObject',
        function(lib, inherit, JsonObject) {
            function Comment(json) {
                JsonObject.call(this, json);

                this.commentDate = lib.moment(json.commentDate);
                this.timestamp = this.commentDate.unix();
            }
            inherit(Comment, [JsonObject]);

            return (Comment);
        }
    ]);

    module.factory('JobOrderForm', [
        'lib', 'inherit', 'Entity', 'Memento',
        function(lib, inherit, Entity, Memento) {
            function JobOrderForm(json) {
                Entity.call(this, json);
            }
            inherit(JobOrderForm, [Entity, Memento]);

            JobOrderForm.prototype.afterUpdate = function() {
                if (this.holdUntil) {
                    this.holdUntil = lib.moment(this.holdUntil);
                }
                if (this.dueBy) {
                    this.dueBy = lib.moment(this.dueBy);
                }
            };

            JobOrderForm.prototype.isOnHold = function() {
                if (this.holdUntil && !lib.moment.isMoment(this.holdUntil)) {
                    this.holdUntil = lib.moment(this.holdUntil);
                }
                return this.holdUntil && this.holdUntil.isAfter();
            };

            return JobOrderForm;
        }
    ]);

    module.factory('JobBillingInfo', [
        'lib', 'inherit', 'Entity', 'Memento',
        function(lib, inherit, Entity, Memento) {
            function JobBillingInfo(json) {
                Entity.call(this, json);

                this.services = this.services || [];
                this.payments = this.payments || [];
            }
            inherit(JobBillingInfo, [Entity, Memento]);

            JobBillingInfo.prototype.afterUpdate = function() {
                if (this.billedOn && !lib.moment.isMoment(this.billedOn)) {
                    this.billedOn = lib.moment(this.billedOn);
                }
            };

            return JobBillingInfo;
        }
    ]);

    module.factory('Elevation', [
        'lib', 'inherit', 'EditableEntity',
        function(lib, inherit, EditableEntity) {
            function Elevation(json) {
                EditableEntity.call(this, json);
            }
            inherit(Elevation, EditableEntity);

            Elevation.prototype.afterUpdate = function() {
                ng.forEach(
                    ['fieldworkDate', 'mapDate', 'firmIndexDate', 'protectedDate', 'noWorkInspectedAt', 'photosTakenOn'],
                    function(dateField) {
                        if (this[dateField]) {
                            this[dateField] = lib.moment(this[dateField]);
                        }
                    }, this);
            };
            
            Elevation.prototype.beforeDiff = function() {
                ng.forEach(
                    ['protectedAreaId', 'bfeSourceId', 'bfeDatumId', 'buildingUseId', 'horizontalDatumId',
                        'elevationSourceId', 'verticalDatumId', 'buildingUseId', 'isSurveyorLicensed',
                        'crawlspaceEngineeredFloodOpenings', 'garageEngineeredFloodOpenings', 'isSeawardOfLmwa',
                        'isConversionFactorUsed', 'hasAttachments'],
                    function(altField) {
                        if (typeof(this[altField]) === 'undefined') {
                            this[altField] = null;
                        }
                    }, this);
            };

            return (Elevation);
        }
    ]);

    module.factory('jobsService', [
        '$http', '$q', 'lib', 'Job', 'Comment', 'Pager', 'Elevation',
        function($http, $q, lib, Job, Comment, Pager, Elevation) {
            var instance = {
                    page: new Pager()
                },
                _locals = {};

            instance.page.itemCallback = function(json) {
                json.createdOn = lib.moment(json.createdOn);
                json.dueBy = json.dueBy ? lib.moment(json.dueBy) : null;
                json.holdUntil = json.holdUntil ? lib.moment(json.holdUntil) : null;
                json.assignedToCrew = json.assignedToCrew ? lib.moment(json.assignedToCrew) : null;

                json.isAssigned = function() { return json.assignedToCrew ? true : false; };
                json.isOverdue = function() {
                    return json.dueBy && json.dueBy.isAfter(lib.moment({hour: 0}));
                };

                return json;
            };

            instance.counts = function() {
                return $http.get('/api/jobs/counts');
            };

            instance.index = function(pageNumber, subset, term, search) {
                var d = $q.defer(),
                    _ = lib.lodash,
                    search = _.pick(search || {}, _.identity);
                var params = {
                    page: pageNumber || 1,
                    subset: subset || 'active'
                };
                if (term) {
                    params.q = term;
                }
                _.assign(params, search);
                $http.get('/api/jobs', { params: params })
                    .success(function(response) {
                        instance.page.update(response.page);
                        d.resolve(response.page);
                    })
                    .error(function(data, status) {
                        d.reject('The server is not responding. (' + status.toString() + ')');
                    });

                return d.promise;
            };

            instance.suggest = function(term, exclude) {
                var d = $q.defer();

                $http.get('/api/jobs/suggest', { params: { q: term, exclude: exclude }})
                    .success(function(response) {
                        d.resolve(response.suggestions);
                    })
                    .error(function(data, status) {
                        d.reject('The server is not responding. (' + status.toString() + ')');
                    });

                return d.promise;
            };

            instance.get = function(id) {
                var d = $q.defer();
                $http.get('/api/jobs/' + id.toString())
                    .success(function(response) {
                        d.resolve(new Job(response.job));
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.post = function(customer, address, orderForm, label, latLon) {
                var d = $q.defer();

                $http.post('/api/jobs', { customerId: customer.id, address: address, orderForm: orderForm, label: label, latLon: latLon })
                    .success(function(response) {
                        var jobId = response.jobId;
                        d.resolve(jobId);
                    })
                    .error(lib.util.httpError(d));

                return d.promise;
            };

            instance.createFollowUp = function(jobId, copyServices) {
                var d = $q.defer();
                $http.post('/api/jobs', { followUpToId: jobId, copyServices: copyServices })
                    .success(function(response) {
                        var jobId = response.jobId;
                        d.resolve(jobId);
                    })
                    .error(lib.util.httpError(d));
                return d.promise;
            };

            instance.patchOrderForm = function(orderForm) {
                var deferred = $q.defer();
                var patch = orderForm.save();
                if (patch.diff) {
                    $http({
                        method: 'PATCH',
                        url: '/api/jobs/' + orderForm.id.toString(),
                        data: patch.diff
                    }).success(function() {
                        deferred.resolve(orderForm);
                    }).error(function(data, status) { //}, headers, config) {
                        orderForm.rollback(patch.orig);
                        deferred.reject('There was an error saving your changes (' + status.toString() + ')');
                    });
                } else {
                    deferred.resolve(orderForm);
                }

                return deferred.promise;
            };

            instance.toggleStar = function(item) {
                var orig = item.starred;
                item.starred = !item.starred;
                return $http
                    .post('/api/jobs/' + item.id.toString() + '/star', { starred: item.starred })
                    .error(function() {
                        item.starred = orig;
                    });
            };

            instance.transferToCustomer = function(job, customer) {
                var deferred = $q.defer();
                var orig = job.orderForm.customer;
                job.orderForm.customer = customer;

                $http({
                        method: 'PATCH',
                        url: '/api/jobs/' + job.id.toString(),
                        data: { customerId: customer.id }
                    }).success(function() {
                        deferred.resolve(customer);
                    }).error(function(data, status) { //, headers, config) {
                        job.orderForm.customer = orig;
                        deferred.reject('There was an error saving your changes. (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance._alternatives = [];
            instance.alternatives = function() {
                if (!instance._alternatives.length) {
                    $http.get('/api/jobs/alternatives')
                        .success(function(response) {
                            instance._alternatives.push.apply(instance._alternatives, response.results);
                        });
                }
                return instance._alternatives;
            };

            _locals.processStateChange = function(response) {
                response.comment = new Comment(response.comment);

                ng.forEach(response.entity, function(value, key) {
                    if (value && key !== 'id') {
                        response.entity[key] = lib.moment(value, lib.moment.ISO_8601).toDate();
                    }
                });
            };

            instance.state = function(job, stateName, enable, reason) {
                var deferred = $q.defer();

                $http
                    .post('/api/jobs/' + job.id.toString() + '/state', { state: stateName, value: enable, reason: reason })
                    .success(function(response) {
                        _locals.processStateChange(response);
                        deferred.resolve(response);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.cancel = function(job, reason) {
                var deferred = $q.defer();

                $http
                    .post('/api/jobs/' + job.id.toString() + '/cancel', { reason: reason })
                    .success(function(response) {
                        _locals.processStateChange(response);
                        deferred.resolve(response);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.uncancel = function(job, reason, recapture) {
                var deferred = $q.defer();

                $http
                    .post('/api/jobs/' + job.id.toString() + '/un-cancel', { recapture: recapture, reason: reason })
                    .success(function(response) {
                        _locals.processStateChange(response);
                        deferred.resolve(response);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.hold = function(job, until, reason) {
                var deferred = $q.defer();

                $http
                    .post('/api/jobs/' + job.id.toString() + '/hold', { until: until, reason: reason })
                    .success(function(response) {
                        _locals.processStateChange(response);
                        deferred.resolve(response);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.bill = function(job) {
                var deferred = $q.defer();
                $http
                    .post('/api/jobs/' + job.id.toString() + '/bill')
                    .success(function(response) {
                        _locals.processStateChange(response);
                        deferred.resolve(response);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.addComment = function(job, content) {
                var deferred = $q.defer();

                $http
                    .post('/api/jobs/' + job.id.toString() + '/comments', { content: content })
                    .success(function(response) {
                        deferred.resolve(new Comment(response.entity));
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.saveService= function(job, service) {
                var method = service.id ? 'PATCH' : 'POST',
                    url = service.id ? '/api/jobs/' + job.id.toString() + '/services/' + service.id.toString()
                        : '/api/jobs/' + job.id.toString() + '/services';
                var deferred = $q.defer();

                $http({
                        method: method,
                        url: url,
                        data: service
                    })
                    .success(function(response) {
                        if (service.id) {
                            deferred.resolve(service);
                        } else {
                            deferred.resolve(response.entity);
                        }
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };

            instance.deleteService = function(job, service) {
                var deferred = $q.defer();

                $http.delete('/api/jobs/' + job.id.toString() + '/services/' + service.id.toString())
                    .success(function(response) {
                        deferred.resolve(response);
                    })
                    .error(lib.util.httpError(deferred));

                return deferred.promise;
            };


            instance.createElevation = function(job, label, address) {
                var deferred = $q.defer();

                $http.post('/api/jobs/' + job.id.toString() + '/elevations', { label: label, address: address })
                    .success(function(response) {
                        deferred.resolve(response.entity);
                    })
                    .error(function(data, status) { //, headers, config) {
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.getElevation = function(jobId, position) {
                var deferred = $q.defer();

                $http.get('/api/jobs/' + jobId.toString() + '/elevations/' + position.toString())
                    .success(function(response) {
                        deferred.resolve(new Elevation(response.entity));
                    })
                    .error(function(data, status) {
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });

                return deferred.promise;
            };

            instance.patchElevation = function(jobId, position, elevation) {
                var deferred = $q.defer();
                var patch = elevation.save();
                if (patch.diff) {
                    $http({
                        method: 'PATCH',
                        url: '/api/jobs/' + jobId.toString() + '/elevations/' + position.toString(),
                        data: patch.diff
                    }).success(function() {
                        deferred.resolve(elevation);
                    }).error(function(data, status) { //, headers, config) {
                        elevation.rollback(patch.orig);
                        deferred.reject('There was an error saving your changes. Please try again (' + status.toString() + ')');
                    });
                } else {
                    elevation.rollback(patch.orig);
                    deferred.resolve(elevation);
                }

                return deferred.promise;
            };

            return instance;
        }
    ]);

}(angular));

