(function(ng) {
    "use strict";
    var module = ng.module('app.customers.controllers', [
        'ui.router', 'ui.bootstrap', 'app.navigation', 'app.services', 'app.controllers', 'app.customers.services'
    ]);

    module.config([
        '$stateProvider',
        function($stateProvider) {
            $stateProvider
                .state('customers', {
                    url: '/customers',
                    template: '<ui-view/>',
                    controller: 'CustomersCtrl'
                })
                .state('customers.details', {
                    url: '/{id:[0-9]+}',
                    templateUrl: '/templates/customers/customer.html',
                    controller: 'CustomerCtrl'
                })
                .state('customers.subset', {
                    url: '/{subset}',
                    templateUrl: '/templates/customers/index.html',
                    controller: 'CustomerRolodexController',
                    abstract: true
                })
                .state('customers.subset.search', {
                    url: '/{page:[0-9]+}?term',
                    templateUrl: '/templates/customers/list.html',
                    controller: 'CustomerListCtrl'
                })
                .state('customers.subset.page', {
                    url: '/{page:[0-9]+}',
                    templateUrl: '/templates/customers/list.html',
                    controller: 'CustomerListCtrl'
                });
        }
    ]);

    module.controller('CustomersCtrl', [
        '$scope',
        function($scope) {
            $scope.doSearch = function(term) {
                $scope.$broadcast('customers.search', term);
            };

            $scope.searchParams = {
                term: ''
            };

            $scope.create = function() {
                $scope.$broadcast('customers.create');
            };

        }
    ]);

    module.controller('CustomerRolodexController', [
        '$scope', '$state', '$stateParams',
        function($scope, $state, $stateParams) {
            $scope.activeNav = {
                name: $stateParams.subset,
                classFor: function(sw) {
                    return sw === $stateParams.subset ? 'btn-primary' : 'btn-default';
                }
            };

            $scope.nav = [
                {sw:'all', label:'All'},
                {sw:'num', label:'123'},
                {sw:'a-d', label:'A-D'},
                {sw:'e-h', label:'E-H'},
                {sw:'i-l', label:'I-L'},
                {sw:'m-p', label:'M-P'},
                {sw:'q-t', label:'Q-T'},
                {sw:'u-z', label:'U-Z'}
            ];

            $scope.onSelectPage = function(pageNumber) {
                $state.go($state.current.name, {page: pageNumber});
            };

            $scope.$on('customers.search', function(event, term) {
                term = term ? term.replace(/^\s+|\s+$/g, '') : '';
                if (term) {
                    $state.go('customers.subset.search', {page: 1, subset: 'all', term: term});
                } else {
                    $state.go('customers.subset.page', {page: 1});
                }
            });
        }
    ]);


    module.controller('CustomerListCtrl', [
        '$scope', '$state', '$stateParams', '$modal', 'customerService', 'Watcher',
        function($scope, $state, $stateParams, $modal, customerService, Watcher) {
            $scope.model = {
                page: customerService.page
            };
            $scope.busyWatcher = new Watcher();

            $scope.searchParams.term = $stateParams.term;

            $scope.create = function() {
                var promise = $modal.open({
                    templateUrl: '/templates/customers/create.html',
                    controller: 'CreateEntityCtrl',
                    resolve: {
                        entity: customerService.create
                    }
                }).result;

                promise.then(function(customer) {
                    $scope.busyWatcher.begin();
                    customerService.post(customer)
                        .then(function(customer) {
                            $state.go('customers.details', { id: customer.id });
                        }).catch(function(reason) {
                            $scope.$emit('toastMessage', { text: reason, level: 'error' });
                        }).finally(function() {
                            $scope.busyWatcher.end();
                        });
                });
            };

            $scope.$on('customers.create', function() {
                $scope.create();
            });

            (function() {
                $scope.busyWatcher.begin();
                customerService.index($stateParams.page || 1, $scope.activeNav.name || 'all', $stateParams.term)
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            })();
        }
    ]);

    module.controller('CustomerCtrl', [
        '$scope', '$stateParams', 'customerService', 'addressLookup', 'Watcher',
        function($scope, $stateParams, customerService, addressLookup, Watcher) {
            $scope.customer = null;
            $scope.locations = addressLookup.locations;
            $scope.busyWatcher = new Watcher();

            $scope.save = function() {
                $scope.busyWatcher.begin();
                customerService.patch($scope.customer)
                    .catch(function(reason) {
                        $scope.$emit('toastMessage', { text: reason, level: 'error' });
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            };

            (function() {
                $scope.busyWatcher.begin();
                customerService.get($stateParams.id)
                    .then(function(surveyor) {
                        $scope.customer = surveyor;
                    })
                    .finally(function() {
                        $scope.busyWatcher.end();
                    });
            })();
        }
    ]);


    module.controller('CustomerWizardCtrl', [
        '$scope', 'addressLookup', 'customerService',
        function($scope, addressLookup, customerService) {
            $scope.title = $scope.title || 'Customer'
            $scope.entity = null;
            $scope.templateName = '/templates/customers/select.html';

            $scope.create = function(name) {
                var entity = customerService.create();
                entity.edit();
                entity.label = name;
                $scope.entity = entity;
                $scope.templateName = '/templates/customers/create.html';
            };

            $scope.searchModel = {
                searchTerm: ''
            };
            $scope.items = customerService.find;
            $scope.selectSuggestion = function($item) { //}, $model, $label) {
                $scope.entity = $item;
            };

            $scope.$watch('searchModel.searchTerm', function(newValue) {
                if ($scope.entity && newValue !== $scope.entity.label) {
                    $scope.entity = null;
                }
            });

            $scope.locations = addressLookup.locations;
            $scope.ok = $scope.selectCustomerCallback || $scope.$close;
        }
    ]);

}(angular));
