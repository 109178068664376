(function(ng) {
    "use strict";

    var module = ng.module('app.directives', ['app.filters', 'app.lib']);

    module.directive('changedBy', function() {
        return {
            restrict: 'AE',
            replace: true,
            scope: {
                'change': '='
            },
            template: '<span>{{ change.username }} {{ change.changeDate | gmailDateTime }}</span>'
        };
    });

    module.directive('requestFocus', function() {
        return function(scope, element, attrs) {
            var e = element[0];
            e.autofocus = true;
            element.ready(function() {
                e.focus();
            });
        };
    });

    module.directive('hasError', function() {
        return function(scope, element, attrs) {
            var watch = [];
            ng.forEach((attrs.hasError || '').split(','), function(prop) {
                watch.push('(' +prop + '.$invalid && ' + prop + '.$dirty)');
            });

            scope.$watch(watch.join(' || '), function(value) {
                return value ?
                    element.addClass('has-error') :
                    element.removeClass('has-error');
            });
        };
    });

    module.directive('errorMessage', function() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                field: '=',
                validator: '@'
            },
            template: '<span ng-if="field.$dirty && field.$error[validator]" class="help-block" ng-transclude></span>',
            link: function(scope, element, attrs) {
            }
        };
    });

    module.directive('card', function() {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: '/templates/common/card.html',
            scope: {
                width: '@',
                heading: '@',
                subheading: '@'
            }
        };
    });

    module.directive('dateField', ['moment',
        function (moment) {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ngModelCtrl) {

                    var dateFormats = (attrs.dateField || 'l|M/D/YY|L').split('|');
                    var parseFormats = ['YYYY-MM-DD', 'YYYY-MM-DDTHH:MM:SS'].concat(dateFormats);

                    ngModelCtrl.$parsers.unshift(function(viewValue) {
                        var parsedMoment;

                        //convert string input into moment data model
                        if (viewValue) {
                            parsedMoment = moment.utc(viewValue, dateFormats, true);
                            //toggle validity
                            ngModelCtrl.$setValidity('date', parsedMoment.isValid());
                        } else {
                            ngModelCtrl.$setValidity('date', true);
                        }

                        //return model value
                        return (parsedMoment && parsedMoment.isValid()) ? parsedMoment : viewValue;
                    });

                    ngModelCtrl.$formatters.unshift(function (modelValue) {
                        var val = modelValue;
                        var isModelADate = modelValue && (angular.isDate(modelValue) || moment.isMoment(modelValue));
                        if (!isModelADate && modelValue) {
                            val = moment(modelValue, parseFormats, true);
                            isModelADate = val.isValid();
                        }
                        ngModelCtrl.$setValidity('date', modelValue ? isModelADate : true);

                        return isModelADate ? moment(val).format(dateFormats[0]) : modelValue;
                    });
                }
            };
        }
    ]);

    module.directive('dateTimeField', ['moment',
        function (moment) {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ngModelCtrl) {

                    var dateFormats = (attrs.dateTimeField || 'l LT|M/D/YY LT|L LT|YYYY-MM-DDTHH:mm:ss').split('|');
                    var parseFormats = ['YYYY-MM-DDTHH:MM:SS'].concat(dateFormats);

                    ngModelCtrl.$parsers.unshift(function(viewValue) {
                        var parsedMoment;

                        //convert string input into moment data model
                        if (viewValue) {
                            parsedMoment = moment.utc(viewValue, dateFormats, true).tz("America/Chicago");
                            //toggle validity
                            ngModelCtrl.$setValidity('date', parsedMoment.isValid());
                        } else {
                            ngModelCtrl.$setValidity('date', true);
                        }

                        //return model value
                        return (parsedMoment && parsedMoment.isValid()) ? parsedMoment : viewValue;
                    });

                    ngModelCtrl.$formatters.unshift(function (modelValue) {
                        var val = modelValue;
                        var isModelADate = modelValue && (angular.isDate(modelValue) || moment.isMoment(modelValue));
                        if (!isModelADate && modelValue) {
                            val = moment(modelValue, parseFormats, true);
                            isModelADate = val.isValid();
                        }
                        ngModelCtrl.$setValidity('date', modelValue ? isModelADate : true);

                        return isModelADate ? moment(val).format(dateFormats[0]) : modelValue;
                    });
                }
            };
        }
    ]);

    module.directive('autofilled', ['$timeout', function ($timeout) {
        return {
            require: 'ngModel',
            scope: {},
            link: function (scope, elem, attrs, ctrl) {
                scope.check = function(){
                    var val = elem[0].value;
                    if(ctrl.$viewValue !== val){
                        var isPristine = ctrl.$pristine;
                        ctrl.$setViewValue(val);
                        // if the form control was originally pristine, set it back to pristine
                        ctrl.$pristine = isPristine;
                    }
                    $timeout(scope.check, 300);
                };
                scope.check();
            }
        }
    }]);

    module.directive('checkMissing', function() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.on('error', function () {
                    element.addClass('missing');
                })
            }
        };
    });

     module.directive('sameAs', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                sameAs: '='
            },
            link: function(scope, elem, attrs, ctrl) {
                scope.$watch(function() {
                    return (ctrl.$pristine && ng.isUndefined(ctrl.$modelValue)) || scope.sameAs === ctrl.$modelValue;
                }, function(currentValue) {
                    ctrl.$setValidity('sameas', currentValue);
                });
            }
        };
    });

})(angular);
