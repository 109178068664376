(function(ng) {
    "use strict";

    var module = ng.module('app.services', ['app.lib']);

    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });

    module.constant('clientId', uuid);

    module.factory('mediator', [
        '$rootScope', '$location', '$interval', 'lodash', 'clientId',
        function($rootScope, $location, $interval, lodash, clientId) {
            var instance = {};

            var socket = null,
                stop = null;
            var path = ($location.protocol().indexOf('s') > -1 ? 'wss://' : 'ws://')
                + $location.host() + '/events?client_id=' + clientId;

            var handlers = {
                onopen: function() {
                    $rootScope.$broadcast('mediator.connected', { connected: true, reason: 'open' });
                },
                onerror: function(event) {
                    instance.disconnect();
                    $rootScope.$broadcast('mediator.disconnected', { connected: false, reason: 'error' });
                },
                onclose: function() {
                    instance.disconnect();
                    $rootScope.$broadcast('mediator.disconnected', { connected: false, reason: 'closed' });
                },
                onmessage: function(message) {
                    if ('pong' !== message.data) {
                        var event = ng.fromJson(message.data);
                        if (event.data['id']) {
                            event.data['id'] = Number(event.data['id']);
                        }
                        $rootScope.$apply(function() {
                            $rootScope.$broadcast(event.name, event);
                        });
                    }
                }
            };

            instance.connect = function() {
                if (!socket) {
                    socket = new WebSocket(path);
                    lodash.assign(socket, handlers);
                }
                if (!stop) {
                    stop = $interval(function() {
                        var s = socket;
                        if (s) {
                            s.send('ping');
                        }
                    }, 15000);
                }
            };

            instance.disconnect = function() {
                if (stop) {
                    $interval.cancel(stop);
                }
                if (socket) {
                    socket.close();
                }
                stop = null;
                socket = null;
            };

            return instance;
        }
    ]);

    module.factory('Memento', ['lodash', function(lodash) {

        var differ = function(first, second) {
            var diff = {},
                orig = {};

            lodash.forEach(lodash.keys(first), function(prop) {
                if (prop === '_orig') {
                    return;
                }
                if (lodash.isArray(first[prop]) && lodash.isArray(second[prop])) {
                    if (lodash.xor(first[prop], second[prop]).length) {
                        diff[prop] = first[prop];
                        orig[prop] = second[prop];
                    }
                } else {
                    if (first[prop] !== second[prop]) {
                        diff[prop] = first[prop];
                        orig[prop] = second[prop];
                    }
                }
            });

            return {
                diff: lodash.isEmpty(diff) ? undefined: diff,
                orig: lodash.isEmpty(orig) ? undefined: orig
            };
        };

        function Memento() {
        }

        Memento.prototype.isEditing = function() {
            return (this._orig) ? true : false;
        };

        Memento.prototype.edit = function() {
            this._orig = lodash.omit(this, lodash.functions(this));
            return this;
        };

        Memento.prototype.revert = function() {
            var orig = this._orig;
            delete this._orig;
            if (!orig) {
                throw 'Calling revert without first calling edit';
            }
            lodash.assign(this, orig);
            lodash.forEach(lodash.difference(lodash.keys(this), lodash.functions(this), lodash.keys(orig)), function(prop) {
                delete this[prop];
            }, this);
        };

        Memento.prototype.save = function() {
            var orig = this._orig;
            delete this._orig;
            if (!orig) {
                throw 'Calling save without first calling edit';
            }
            this.beforeDiff();
            return differ(this, orig);
        };

        Memento.prototype.diff = function() {
            var orig = this._orig;
            if (!orig) {
                throw 'Calling diff without first calling edit';
            }
            this.beforeDiff();
            return differ(this, orig);
        };

        Memento.prototype.beforeDiff = function() {
            // override to customize
        };

        Memento.prototype.rollback = function(orig) {
            orig = orig || this._orig;
            delete this._orig;
            if (orig) {
                lodash.assign(this, orig);
            }
        };

        return (Memento);
    }]);

    module.factory('JsonObject', ['lodash', function(lodash) {
        function JsonObject(json) {
            if (json) {
                this.update(json);
            }
        }

        JsonObject.prototype.update = function(json) {
            this.beforeUpdate(json);
            lodash.assign(this, json);
            this.afterUpdate();
        };

        JsonObject.prototype.beforeUpdate = function(json) {
            // override to customize
        };

        JsonObject.prototype.afterUpdate = function() {
            // override to customize
        };

        return JsonObject;
    }]);

    module.factory('Entity', [
        'lodash', 'inherit', 'JsonObject',
        function(lodash, inherit, JsonObject) {

            function Entity(json) {
                JsonObject.call(this, json);
            }

            inherit(Entity, JsonObject);

            Entity.prototype.invalidate = function() {
                lodash.difference(lodash.keys(this), lodash.functions(this)).forEach(function(key) {
                    if (key !== 'id') {
                        delete this[key];
                    }
                }, this);
            };

            Entity.prototype.replace = function(json) {
                this.invalidate();
                this.update(json);
            };

            return (Entity);
        }
    ]);

    module.factory('EditableEntity', [
        'inherit', 'Memento', 'Entity',
        function(inherit, Memento, Entity) {
            function EditableEntity(json) {
                Entity.call(this, json);
            }

            inherit(EditableEntity, [Entity, Memento]);

            return (EditableEntity);
        }
    ]);

    module.factory('inherit', ['lodash', function(lodash) {
        var inherit = function(ctor, extend) {
            if (lodash.isFunction(extend)) {
                ctor.prototype = lodash.create(extend.prototype, { 'constructor': ctor });
            } else if (lodash.isArray(extend)) {
                ctor.prototype = lodash.create(extend[0].prototype, { 'constructor': ctor });
                lodash.forEach(lodash.rest(extend, 1), function(sup) {
                    lodash.assign(ctor.prototype, sup.prototype);
                });
            }
            return ctor.prototype;
        };

        return (inherit);
    }]);


    module.factory('sockets', [
        '$location', '$timeout', '$rootScope',
        function($location, $timeout, $rootScope) {
            var instance = {
                appRoot: ($location.protocol().indexOf('s') > -1 ? 'wss://' : 'ws://')
                    + $location.host()
            };

            instance.createListener = function(path, subscriber) {
                var ws = {},
                    wrapper = {
                        socket: null,
                        reconnectDelay: 0,
                        reconnectOnError: false,
                        subscribers: []
                    };

                if (subscriber) {
                    wrapper.subscribers.push(subscriber);
                }
                if (path.indexOf('/') != 0) {
                    path = '/' + path;
                }
                path = instance.appRoot + path;

                ws.reconnect = function(w) {
                    var delay = w.reconnectDelay;
                    if (w.reconnectOnError) {
                        w.reconnectDelay = Math.max(w.reconnectDelay * 2, 100);
                        w.timeout = $timeout(w.connect, delay);
                    }
                };

                wrapper.connect = function() {
                    if (wrapper.reconnectOnError) {
                        wrapper.socket = new WebSocket(path);
                        wrapper.socket.onopen = function() {
                            wrapper.reconnectDelay = 0;
                        };
                        wrapper.socket.onerror = function() {
                            ws.reconnect(wrapper);
                        };
                        wrapper.socket.onclose = function() {
                            ws.reconnect(wrapper);
                        };

                        wrapper.socket.onmessage = function(message) {
                            var data = ng.fromJson(message.data);
                            ng.forEach(wrapper.subscribers, function(sub) {
                                sub(data);
                            });
                        };
                    }
                };

                ws.connect = function() {
                    wrapper.reconnectOnError = true;
                    wrapper.connect();
                };

                ws.disconnect = function() {
                    $timeout.cancel(wrapper.timeout);
                    wrapper.reconnectOnError = false;
                    if (wrapper.socket) {
                        wrapper.socket.close();
                        wrapper.socket = null;
                    }
                };

                $rootScope.$on('event:user-signed-in', ws.connect);
                $rootScope.$on('event:user-signed-out', ws.disconnect);

                ws.subscribe = function(listener) {
                    wrapper.subscribers.push(listener);
                };

                ws.unsubscribe = function(listener) {
                    var idx = wrapper.subscribers.indexOf(listener);
                    if (idx > -1) {
                        wrapper.subscribers.splice(idx, 1);
                    }
                };

                return ws;
            };

            return instance;
        }
    ]);

    module.factory('addressLookup', ['$http', function($http) {
        var instance = {};

        instance.locations = function(address) {
            return $http.get('/api/geocode', {
                params: {
                    address: address
                }
            }).then(function(response) {
                return response.data.results;
            });
        };

        instance.addresses = function(address, includeLatLon) {
            includeLatLon = includeLatLon || '';
            return $http.get('/api/address', {
                params: {
                    address: address,
                    latlon: includeLatLon
                }
            }).then(function(response) {
                return response.data.results;
            });
        };

        instance.latLon = function(address) {
            return $http.get('/api/address', {
                params: {
                    address: address,
                    latlon: 1
                }
            }).then(function(response) {
                var results = response.data.results;
                if (results.length && results[0].longitude && results[0].latitude) {
                    return { longitude: results[0].longitude, latitude: results[0].latitude };
                } else {
                    return null;
                }
            });
        };

        return instance;
    }]);

}(angular));
